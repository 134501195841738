import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Document, Page, View, StyleSheet, Font } from '@react-pdf/renderer';

import {
  PatientReportCheckupDataFragment,
  PatientReportPatientDataFragment,
  PatientReportAdmissionDataFragment,
  Maybe,
  PatientReportAlertsDataFragment,
  PatientReportNotesDataFragment,
  PatientReportVitalsAggregateFragment,
  PatientReportSoftSignsAggregateFragment,
  PatientReportAdmissionEventDataFragment,
  AdmissionEventType,
} from '@/generated/graphql';

import FeebrisLogoForPDF from '@/images/FeebrisLogoForPDF';
import { feebrisFormatter } from '@/helpers/LocaleFormatting';
import { formatNhsNumber } from '@/components/NhsNumber';
import { isDefined } from '@/helpers/isDefined';
import { WithoutTypeNames, omitTypenameDeep } from '@/AuthorizedApolloClientProvider';

import RobotoRegular from './fonts/Roboto-Regular.ttf';
import RobotoMedium from './fonts/Roboto-Medium.ttf';
import { NEWS_SCORE_PALETTE } from '@/styles/NEWSColors';
import { questionKey } from '@/helpers/questionnaire';
import { isEmergencyQuestion } from '@/helpers/Checkup';
import { formatUserName } from '@/helpers/formatUserName';
import { AdmissionEvent, AdmissionHistory, useAdmissionHistories } from './useAdmissionHistories';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: RobotoRegular, fontWeight: 400 },
    { src: RobotoMedium, fontWeight: 500 },
  ],
});

const NO_DATA_STRING = 'Not taken';
const MAX_DATE = new Date(8640000000000000);

const MAX_CHECKUPS = 100;

interface PatientReportDocumentProps {
  reportFrom: Date;
  reportTo: Date;
  patient: PatientReportPatientDataFragment;
  checkups: PatientReportCheckupDataFragment[] | undefined;
  vitalsSummary: PatientReportVitalsAggregateFragment[] | undefined;
  softSignsSummary: PatientReportSoftSignsAggregateFragment[] | undefined;
  currentWardAdmission: Maybe<PatientReportAdmissionDataFragment> | undefined;
  admissionEvents: PatientReportAdmissionEventDataFragment[] | undefined;
  notes: PatientReportNotesDataFragment[] | undefined;
  alerts: PatientReportAlertsDataFragment[] | undefined;
}

/**
 * Check if two date ranges overlap
 */
const checkDateRangesOverlap = (
  firstStart: Date,
  firstEnd: Date,
  secondStart: Date,
  secondEnd: Date,
) => firstStart.getTime() <= secondEnd.getTime() && firstEnd.getTime() >= secondStart.getTime();

const filterAdmissionHistoriesForWindow = (
  admissionHistories: AdmissionHistory[],
  reportFrom: Date,
  reportTo: Date,
) => {
  return (
    admissionHistories
      /**
       * We need to select only the ward memberships that were active during the report window.
       * In the case of the current ward admission, we set the discharge date to infinity for simplicity.
       */
      .filter((admissionHistory) =>
        checkDateRangesOverlap(
          new Date(admissionHistory.admission.createdAt),
          admissionHistory.latest ? new Date(admissionHistory.latest.createdAt) : MAX_DATE,
          reportFrom,
          reportTo,
        ),
      )
      .sort(
        (a, b) =>
          new Date(b.admission.createdAt).getTime() - new Date(a.admission.createdAt).getTime(),
      )
  );
};

export function PatientReportDocument({
  patient,
  checkups,
  vitalsSummary,
  softSignsSummary,
  reportFrom,
  reportTo,
  admissionEvents,
  notes,
  alerts,
}: PatientReportDocumentProps) {
  const { t } = useTranslation();

  // FIXME: This is not correct, a patient's address isn't the address of their first organisation. Perhaps the whole patient address inheritance needs a rethink?
  const address = patient.address ? patient.address : patient.organizations[0]?.address;

  const renderTextWithHeader = (headerText: string, bodyText: React.ReactNode, inline: boolean) => (
    <View style={inline ? styles.row : styles.col}>
      <Text style={styles.textStrong}>{headerText}</Text>
      <Text>{bodyText ?? NO_DATA_STRING}</Text>
    </View>
  );

  const renderAdmissionEvent = (admissionEvent: AdmissionEvent) => (
    <>
      <View style={styles.row}>
        <Text style={styles.textStrong}>
          {admissionEvent.type === AdmissionEventType.Admission
            ? 'Admission '
            : admissionEvent.type === AdmissionEventType.Discharge
            ? 'Discharge '
            : admissionEvent.type === AdmissionEventType.CarePathwayTransfer ||
              admissionEvent.type === AdmissionEventType.WardTransfer
            ? 'Transfer '
            : ''}
        </Text>

        {admissionEvent.type === AdmissionEventType.WardTransfer && (
          <>
            <Text>{'to ward '}</Text>
            <Text style={styles.textStrong}>{`${admissionEvent.wardName} `}</Text>
          </>
        )}

        {admissionEvent.type === AdmissionEventType.CarePathwayTransfer && (
          <>
            <Text>{'to care pathway '}</Text>
            <Text style={styles.textStrong}>{`${admissionEvent.carePathwayName} `}</Text>
          </>
        )}

        <Text>
          {'at '}
          {t('DATETIME_SHORT', {
            val: new Date(admissionEvent.createdAt),
          })}{' '}
        </Text>

        {admissionEvent.type === AdmissionEventType.Discharge && (
          <>
            <Text>{'by '}</Text>
            <Text style={styles.textStrong}>
              {formatUserName(admissionEvent.createdBy, admissionEvent.organization)}
            </Text>
          </>
        )}
      </View>
    </>
  );

  const renderDemographicsChart = () => (
    <View style={styles.callout}>
      <View style={[styles.row, styles.rowEvenSpaced]}>
        <View style={styles.col20pc}>
          {renderTextWithHeader('Patient name', `${patient.firstName} ${patient.lastName}`, false)}
        </View>
        <View style={styles.col20pc}>
          {renderTextWithHeader(
            'Date of birth',
            t('DATE_SHORT', { val: new Date(patient.birthDate) }),
            false,
          )}
        </View>
        <View style={styles.col20pc}>{renderTextWithHeader('Gender', patient.gender, false)}</View>
        <View style={styles.col20pc}>
          {renderTextWithHeader(
            'NHS number',
            patient.nhsNumberResponseDetails?.nhsNumber
              ? formatNhsNumber(patient.nhsNumberResponseDetails?.nhsNumber)
              : NO_DATA_STRING,
            false,
          )}
        </View>
        <View style={styles.col20pc}>
          {renderTextWithHeader('Home address', address?.address ?? NO_DATA_STRING, false)}
        </View>
      </View>
    </View>
  );

  const renderDocMeta = () => (
    <View style={styles.calloutSub}>
      <View style={[styles.row, styles.rowEvenSpaced]}>
        <View style={styles.col25pc}>
          {renderTextWithHeader(
            'Patient registered',
            t('DATETIME_SHORT', {
              // createdDate is coming from the Patient data in state from PatientSerializer.js
              // We could take patient from the checkups gql, but if patient has no checkups, it gets messy.
              val: new Date(patient.createdAt),
              formatParams: { val: { timeZone: 'UTC' } },
            }),
            false,
          )}
        </View>
        <View style={styles.col75pc}>
          {renderTextWithHeader(
            'Report window',
            `From: ${t('DATETIME_SHORT', {
              val: reportFrom,
            })} \nTo: ${t('DATETIME_SHORT', {
              val: reportTo,
            })}`,
            false,
          )}
        </View>
      </View>
    </View>
  );

  const maybeRenderWardAdmissions = (admissionHistories: AdmissionHistory[]) => {
    if (admissionHistories.length === 0) {
      return null;
    }

    const filteredAdmissionHistories = filterAdmissionHistoriesForWindow(
      admissionHistories,
      reportFrom,
      reportTo,
    );

    return (
      <View style={[styles.col, styles.section]}>
        <Text style={styles.headerText}>Ward Admissions: {filteredAdmissionHistories.length}</Text>
        {filteredAdmissionHistories.length ? (
          filteredAdmissionHistories.map((admissionHistory, index) => (
            <View style={styles.sectionBox} key={index}>
              <Text style={styles.sectionSubheader}>
                <Text style={styles.textStrong}>Admission</Text>
                <Text>{' to ward '}</Text>
                <Text style={styles.textStrong}>{`${admissionHistory.admission.wardName} `}</Text>
                {admissionHistory.admission.carePathwayName ? (
                  <>
                    <Text>{'with care pathway '}</Text>
                    <Text style={styles.textStrong}>
                      {admissionHistory.admission.carePathwayName}
                    </Text>
                  </>
                ) : (
                  ''
                )}
                {' on '}
                {t('DATETIME_LONG', { val: new Date(admissionHistory.admission.createdAt) })}
              </Text>
              <View style={styles.sectionIndent}>
                {admissionHistory.transfers.map((transfer) => renderAdmissionEvent(transfer))}
                {admissionHistory.discharge && renderAdmissionEvent(admissionHistory.discharge)}
              </View>
            </View>
          ))
        ) : (
          <Text>This patient has no ward admissions between the selected dates.</Text>
        )}
      </View>
    );
  };

  const maybeRenderCheckups = () => {
    if (!checkups) {
      return null;
    }

    if (checkups.length > MAX_CHECKUPS) {
      return (
        <View style={[styles.col, styles.section]}>
          <Text style={styles.headerText}>Checkups: {checkups.length}</Text>
          <Text>
            This patient has too many checkups between the selected dates. Please narrow the date
            range to view them.
          </Text>
        </View>
      );
    }

    return (
      <View style={[styles.col, styles.section]}>
        <Text style={styles.headerText}>Checkups: {checkups.length}</Text>
        {checkups.length ? (
          checkups.map((checkup, index) => (
            <View style={styles.sectionBox} key={checkup.id}>
              <Text style={styles.sectionSubheader}>
                {index + 1}. {t('DATETIME_LONG', { val: new Date(checkup.createdAt) })}
              </Text>
              <View style={styles.sectionIndent}>
                {renderTextWithHeader(
                  'Start time:',
                  t('DATETIME_LONG', { val: new Date(checkup.startedAt) }),
                  true,
                )}
                {renderTextWithHeader('EWS score:', checkup?.ewsScores?.totalScore, true)}
                {renderTextWithHeader('Breathing rate:', checkup.respiratoryRate?.value, true)}
                {renderTextWithHeader('SpO2:', checkup.pulseOxiData?.averageSpO2, true)}
                {renderTextWithHeader(
                  'Blood pressure:',
                  checkup.bloodPressureData
                    ? ` Pulse: ${checkup.bloodPressureData.pulse ?? NO_DATA_STRING} / ` +
                        ` Systolic: ${checkup.bloodPressureData.systolic ?? NO_DATA_STRING} / ` +
                        ` Diastolic: ${checkup.bloodPressureData.diastolic ?? NO_DATA_STRING} / ` +
                        ` Position: ${checkup.bloodPressureData.position ?? NO_DATA_STRING} / ` +
                        ` Manual reading: ${checkup.bloodPressureData.isManual ? 'Yes' : 'No'}`
                    : NO_DATA_STRING,
                  true,
                )}
                {renderTextWithHeader('Pulse rate:', checkup.pulseRate?.value, true)}
                {renderTextWithHeader(
                  'Consciousness:',
                  checkup.consciousness
                    ? `${t(`CONSCIOUSNESS_SHORT.${checkup.consciousness}`)} - ${t(
                        `CONSCIOUSNESS_LONG.${checkup.consciousness}`,
                      )}`
                    : NO_DATA_STRING,
                  true,
                )}
                {renderTextWithHeader(
                  'Temperature:',
                  `${checkup.temperature ? checkup.temperature + 'C' : NO_DATA_STRING}`,
                  true,
                )}
                {renderTextWithHeader(
                  'Weight:',
                  `${checkup.weight ? checkup.weight + 'KG' : NO_DATA_STRING}`,
                  true,
                )}
                {renderTextWithHeader(
                  'Glucose:',
                  checkup.glucose
                    ? ` Reading: ${
                        checkup.glucose.reading
                          ? feebrisFormatter.formatGlucoseWithUnits(checkup.glucose.reading)
                          : NO_DATA_STRING
                      } / ` +
                        ` Time of capture: ${
                          checkup.glucose.time_of_capture ?? NO_DATA_STRING
                        } / ` +
                        ` Taken at: ${
                          checkup.glucose.before_after_meal
                            ? checkup.glucose.before_after_meal.replace('_', ' ')
                            : NO_DATA_STRING
                        }`
                    : NO_DATA_STRING,
                  true,
                )}
                {renderTextWithHeader(
                  'Symptoms:',
                  checkup.questionnaire?.length
                    ? checkup.questionnaire
                        // Types generated from gql
                        .map((question) =>
                          question?.answer !== 0
                            ? ` - ${question?.qnKey}\n`.replaceAll('_', ' ')
                            : null,
                        )
                        .filter((symptom: string | null) => symptom !== null)
                        .toString()
                        .replaceAll(',', '')
                    : NO_DATA_STRING,
                  checkup.questionnaire ? false : true,
                )}
                {renderTextWithHeader(
                  'Lung sounds captured:',
                  checkup.lungSounds ? 'Yes' : 'No',
                  true,
                )}
                {renderTextWithHeader('Well/unwell:', checkup.isStable ? 'Well' : 'Unwell', true)}
                {renderTextWithHeader(
                  'Check-up notes:',
                  checkup.notes?.length ? checkup.notes.map((c) => c.text).join('\n') : null,
                  false,
                )}
                {renderTextWithHeader(
                  'Selected action:',
                  t([`CHECKUP_ACTIONS_SHORT.${checkup.selectedAction}`, NO_DATA_STRING]).toString(),
                  true,
                )}
                {renderTextWithHeader(
                  'Check-up carried out by:',
                  checkup.createdBy
                    ? formatUserName(checkup.createdBy, checkup.organization)
                    : NO_DATA_STRING,
                  true,
                )}
              </View>
            </View>
          ))
        ) : (
          <Text>This patient has no checkups between the selected dates.</Text>
        )}
      </View>
    );
  };

  const renderVitalSignAggregates = (
    key: keyof WithoutTypeNames<PatientReportVitalsAggregateFragment['values']>,
    vitalsSummary: PatientReportVitalsAggregateFragment[],
    title?: string,
  ) => {
    const vitalSignAggregates = vitalsSummary
      .map((va) => {
        const aggregateWithoutCursedTypename = omitTypenameDeep(va);

        const vitalSign = aggregateWithoutCursedTypename.values[key];

        // If there's no data for this vital sign in this row - skip it
        if (!vitalSign || ![vitalSign?.median, vitalSign?.min, vitalSign?.max].some(isDefined)) {
          return null;
        }

        return {
          start: va.start,
          end: va.end,
          median: vitalSign?.median,
          min: vitalSign?.min,
          max: vitalSign?.max,
        };
      })
      .filter(isDefined);

    if (!vitalSignAggregates.length) {
      return null;
    }

    return (
      <View style={styles.section} key={key}>
        <Text style={styles.subheaderText}>{`${title ?? key}:`}</Text>
        <Table
          data={vitalSignAggregates}
          columns={[
            {
              title: 'Start',
              renderer: (item) =>
                t('DATETIME_SHORT', {
                  val: new Date(item.start),
                  formatParams: { val: { timeZone: 'UTC' } },
                }),
              width: 20,
            },
            {
              title: 'End',
              renderer: (item) =>
                t('DATETIME_SHORT', {
                  val: new Date(item.end),
                  formatParams: { val: { timeZone: 'UTC' } },
                }),
              width: 20,
            },
            {
              title: 'Median',
              renderer: 'median',
            },
            {
              title: 'Min',
              renderer: 'min',
            },
            {
              title: 'Max',
              renderer: 'max',
            },
          ]}
        />
      </View>
    );
  };

  const maybeRenderVitalsAggregates = () => {
    if (!vitalsSummary) {
      return null;
    }

    const vitalSignsSections = [
      renderVitalSignAggregates('pulseRate', vitalsSummary, 'Pulse Rate'),
      renderVitalSignAggregates('respiratoryRate', vitalsSummary, 'Respiratory Rate'),
      renderVitalSignAggregates('systolicBloodPressure', vitalsSummary, 'Systolic Blood Pressure'),
      renderVitalSignAggregates(
        'diastolicBloodPressure',
        vitalsSummary,
        'Diastolic Blood Pressure',
      ),
      renderVitalSignAggregates('spO2', vitalsSummary),
      renderVitalSignAggregates('temperature', vitalsSummary),
      renderVitalSignAggregates('weight', vitalsSummary),
      renderVitalSignAggregates('glucose', vitalsSummary),
    ].filter(isDefined);

    return (
      <View style={[styles.col, styles.section]}>
        <Text style={styles.headerText}>Vitals Summaries:</Text>
        {vitalSignsSections.length ? (
          vitalSignsSections
        ) : (
          <Text>This patient has no vital signs recorded for the report dates.</Text>
        )}
      </View>
    );
  };

  const maybeRenderSoftSignsAggregates = () => {
    if (!softSignsSummary) {
      return null;
    }

    return (
      <View style={styles.section}>
        <Text style={styles.headerText}>{`Soft Signs:`}</Text>
        <Table
          data={softSignsSummary}
          columns={[
            {
              title: 'Start',
              renderer: (item) =>
                t('DATETIME_SHORT', {
                  val: new Date(item.start),
                  formatParams: { val: { timeZone: 'UTC' } },
                }),
              width: 13,
            },
            {
              title: 'End',
              renderer: (item) =>
                t('DATETIME_SHORT', {
                  val: new Date(item.end),
                  formatParams: { val: { timeZone: 'UTC' } },
                }),
              width: 13,
            },
            {
              title: 'Soft Signs',
              withoutTextWrap: true,
              renderer: (item) => {
                return (
                  <View style={styles.softSignsContainer}>
                    {item.values.map((softSign) => {
                      if (softSign.count === 0) {
                        return null;
                      }

                      return (
                        <View
                          style={[
                            styles.softSignsChip,
                            isEmergencyQuestion(softSign.questionKey)
                              ? styles.softSignsEmergencyChip
                              : null,
                          ].filter(isDefined)}
                          key={softSign.questionKey}
                          wrap={false}>
                          <Text style={styles.softSignsValue}>
                            {questionKey[softSign.questionKey as keyof typeof questionKey] ||
                              softSign.questionKey}
                          </Text>
                          <Text style={styles.softSignsCount}>
                            <Text style={styles.invisibleCharacter}>&#40;</Text>
                            {softSign.count}
                            <Text style={styles.invisibleCharacter}>&#41;&#44;&#32;</Text>
                          </Text>
                        </View>
                      );
                    })}
                  </View>
                );
              },
            },
          ]}
        />
      </View>
    );
  };

  const maybeRenderNotes = () => {
    if (!isDefined(notes)) {
      return null;
    }

    return (
      <View style={[styles.col, styles.section]}>
        <Text style={styles.headerText}>Notes: {notes.length}</Text>
        <Table
          data={notes}
          columns={[
            {
              title: 'Note',
              renderer: (item) => item.text,
            },
            {
              title: 'Logged by',
              renderer: (item) =>
                item.createdBy ? formatUserName(item.createdBy, item.organization) : 'Unknown',
              width: 30,
            },
            {
              title: 'Time',
              renderer: (item) =>
                item.createdAt ? t('DATETIME_SHORT', { val: new Date(item.createdAt) }) : 'Unknown',
              width: 22,
            },
          ]}
        />
      </View>
    );
  };

  const maybeRenderAlerts = () => {
    if (!isDefined(alerts)) {
      return null;
    }

    return (
      <View style={[styles.col, styles.section]}>
        <Text style={styles.headerText}>Alerts: {alerts.length}</Text>
        <Table
          columns={[
            {
              title: 'Name',
              renderer: (item) => item.alertRule?.name ?? 'Unknown',
              width: 28,
            },
            {
              title: 'Description',
              renderer: (item) => item.alertRule?.description ?? 'Unknown',
            },
            {
              title: 'Time',
              width: 22,
              renderer: (item) =>
                item.startedAt ? t('DATETIME_SHORT', { val: new Date(item.startedAt) }) : 'Unknown',
            },
          ]}
          data={alerts}
        />
      </View>
    );
  };

  const admissionHistories = useAdmissionHistories(admissionEvents ?? [], true);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.rowLogoTitle}>
            <View style={styles.logo}>
              <FeebrisLogoForPDF width={80} />
            </View>
            <Text style={styles.headerTextMain}>
              {patient.firstName} {patient.lastName} Patient Report
            </Text>
          </View>
          {renderDemographicsChart()}
          {renderDocMeta()}
          {maybeRenderWardAdmissions(admissionHistories)}
          {maybeRenderVitalsAggregates()}
          {maybeRenderSoftSignsAggregates()}
          {maybeRenderAlerts()}
          {maybeRenderNotes()}
          {maybeRenderCheckups()}
        </View>
        <Text
          style={styles.pageFooter}
          render={({ pageNumber, totalPages }) =>
            `Feebris patient Summary for ${patient.firstName} ${patient.lastName} | ` +
            t('DATETIME_LONG', {
              val: new Date(),
              formatParams: { val: { timeZone: 'UTC' } },
            }) +
            `\nPage ${pageNumber} of ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    fontSize: 10,
    fontFamily: 'Roboto',
    padding: '32px',
    paddingBottom: '80px',
    color: '#000',
  },
  section: {
    marginBottom: '16px',
    marginTop: '16px',
  },
  row: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    textAlign: 'left',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
  },
  rowEvenSpaced: {
    justifyContent: 'space-around',
    textAlign: 'left',
    alignItems: 'flex-start',
  },
  col: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    textAlign: 'left',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    lineHeight: '1.5',
  },
  col20pc: {
    width: '20%',
  },
  col25pc: {
    width: '25%',
  },
  col50pc: {
    width: '50%',
  },
  col75pc: {
    width: '75%',
  },
  textStrong: {
    marginRight: '2px',
    marginBottom: '2px',
    color: '#009ac9',
    fontWeight: 'bold',
  },
  rowLogoTitle: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    alignContent: 'center',
    alignItems: 'center',
    marginBottom: '8px',
    color: '#204957',
  },
  logo: {
    marginRight: '8px',
  },
  headerTextMain: {
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 500,
    marginTop: '8px',
    marginBottom: '8px',
  },
  headerText: {
    fontSize: '14px',
    fontWeight: 500,
    marginBottom: '4px',
    textTransform: 'capitalize',
  },
  subheaderText: {
    fontSize: '12px',
    fontWeight: 500,
    marginBottom: '4px',
    textTransform: 'capitalize',
  },
  checkupItems: {
    marginBottom: '8px',
  },
  callout: {
    border: '1px solid #c4c4c4',
    padding: '8px',
    marginVertical: '8px',
    borderRadius: '4px',
  },
  calloutSub: {
    border: '1px solid #c4c4c4',
    padding: '8px',
    marginVertical: '8px',
    borderRadius: '4px',
  },
  calloutHeader: {
    marginBottom: '12px',
  },
  sectionBox: {
    borderBottom: '1px solid #c4c4c4',
    paddingBottom: '16px',
    marginBottom: '8px',
    width: '100%',
  },
  sectionSubheader: {
    marginBottom: '8px',
  },
  sectionIndent: {
    marginLeft: '8px',
    paddingLeft: '8px',
    borderLeft: '1px solid #c4c4c4',
  },
  pageFooter: {
    position: 'absolute',
    fontSize: 8,
    bottom: '24px',
    left: 0,
    right: 0,
    textAlign: 'center',
    color: '#c4c4c4',
    lineHeight: 1.75,
  },
  invisibleCharacter: {
    opacity: 0,
    fontSize: 0.1,
  },
  wardDeleted: {
    fontSize: 6,
  },
  softSignsChip: {
    margin: '4px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '16px',
    lineHeight: 1,
    border: '1px solid transparent',
    ...NEWS_SCORE_PALETTE[1],
  },
  softSignsEmergencyChip: {
    ...NEWS_SCORE_PALETTE[3],
  },
  softSignsCount: {
    fontSize: 10,
    fontWeight: 500,
    padding: '4px',
    margin: '2px',
    borderRadius: '50%',
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    backgroundBlendMode: 'color-burn',
    lineHeight: 1,
    userSelect: 'none',
  },
  softSignsValue: {
    fontSize: 10,
    margin: '2px',
    marginLeft: '6px',
    userSelect: 'none',
  },
  softSignsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});

interface ColumnDefinition<TData> {
  title: string;
  renderer: keyof TData | ((item: TData) => React.ReactNode);
  width?: number;
  withoutTextWrap?: boolean;
}

interface TableProps<T> {
  columns: ColumnDefinition<T>[];
  data: T[];
  noDataPlaceholder?: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function Table<T extends Record<string, any>>({
  columns,
  data,
  noDataPlaceholder = 'No data',
}: TableProps<T>) {
  const defaultWidth =
    (100 - columns.reduce((acc, curr) => acc + (curr?.width ?? 0), 0)) /
    columns.filter((c) => !isDefined(c.width)).length;

  return (
    <View style={tableStyles.table}>
      <View style={tableStyles.tableRow} wrap={false}>
        {columns.map((column) => (
          <View
            key={column.renderer.toString()}
            style={[
              tableStyles.tableCol,
              {
                width: (column.width ?? defaultWidth) + '%',
              },
            ]}>
            <Text style={tableStyles.tableHeadCell}>{column.title}</Text>
          </View>
        ))}
      </View>
      {data.length === 0 && (
        <View style={tableStyles.tableRow} wrap={false}>
          <View
            style={[
              tableStyles.tableCol,
              {
                width: '100%',
              },
            ]}>
            <Text style={tableStyles.emptyCell}>{noDataPlaceholder}</Text>
          </View>
        </View>
      )}
      {data.map((item, i) => (
        <View key={i} style={tableStyles.tableRow}>
          {columns.map((column) => {
            const rendered =
              (typeof column.renderer === 'function'
                ? column.renderer(item)
                : item[column.renderer].toString()) ?? 'Unknown';

            const ColumnWrapper = typeof rendered === 'string' ? Text : React.Fragment;

            return (
              <View
                key={column.renderer.toString()}
                style={[
                  tableStyles.tableCol,
                  {
                    width: (column.width ?? defaultWidth) + '%',
                  },
                ]}>
                <View style={tableStyles.tableCell}>
                  <ColumnWrapper>
                    {(typeof column.renderer === 'function'
                      ? column.renderer(item)
                      : item[column.renderer].toString()) ?? 'Unknown'}
                  </ColumnWrapper>
                </View>
              </View>
            );
          })}
        </View>
      ))}
    </View>
  );
}

const tableStyles = StyleSheet.create({
  table: {
    display: 'flex',
    width: 'auto',
    borderColor: '#c4c4c4',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    borderColor: '#c4c4c4',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    lineHeight: 1,
  },
  tableHeadCell: {
    fontWeight: 500,
    margin: 4,
    fontSize: 10,
  },
  tableCell: {
    margin: 4,
    fontSize: 10,
    lineHeight: 1.25,
  },
  emptyCell: {
    margin: 4,
    fontSize: 10,
    textAlign: 'center',
  },
});
