import React, { useMemo } from 'react';

import { Box, Chip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { QuestionnaireQuestion, generateSoftSignsData } from '@/helpers/questionnaire';

export interface SoftSignsChipsProps {
  questionnaire: QuestionnaireQuestion[];
  showHeader?: boolean;
}

export function SoftSignsChips({ questionnaire, showHeader = true }: SoftSignsChipsProps) {
  const classes = useStyles();

  const softSigns = useMemo(() => generateSoftSignsData(questionnaire), [questionnaire]);

  if (!softSigns.length) {
    return null;
  }

  return (
    <div>
      {showHeader && <Typography className={classes.label}>Soft Signs</Typography>}
      <Box marginTop={0.75}>
        {softSigns.map((softSign) => (
          <Chip
            data-testid="soft-sign-chip"
            key={softSign.text}
            label={softSign.text}
            sx={{
              marginBottom: 1,
              marginRight: 1,
            }}
            color={softSign.isEmergencyQuestion ? 'error' : 'warning'}
          />
        ))}
      </Box>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 500,
  },
}));
