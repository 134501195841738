import React, { useEffect, useState, useMemo } from 'react';

import _ from 'lodash';
import { useFormik } from 'formik';
import {
  Alert,
  AlertTitle,
  Box,
  TextField,
  Typography,
  Link as MuiLink,
  Grid,
} from '@mui/material';
import * as Yup from 'yup';
import { ApolloError, gql } from '@apollo/client';
import { toast } from 'sonner';

import { useLookupPatientLazyQuery } from '@/generated/graphql';

import { getMutationErrors } from '@/AuthorizedApolloClientProvider';
import { useMeActingOrganizationFeature } from '@/hooks/useAuth';
import { muiFormikGetFieldProps } from '@/helpers/formik';
import { createTapProps } from '@/helpers/createTapProps';

import { useAdmitPatientJourneyContext } from '../types';

const searchFormSchema = Yup.object().shape(
  {
    birthDate: Yup.date()
      .required('Date of birth is required')
      .max(new Date(), 'Date of birth must be in the past'),
    nhsNumber: Yup.string().optional(),
    firstName: Yup.string()
      .optional()
      // If the last name is provided, then we require the first name
      .when('lastName', {
        is: (lastName: string) => lastName && lastName.length > 0,
        then: (schema) => schema.required('Both first and last must be provided to search by name'),
        otherwise: (schema) => schema.nullable(),
      }),
    lastName: Yup.string()
      // If the first name is provided, then we require the last name
      .when('firstName', {
        is: (firstName: string) => firstName && firstName.length > 0,
        then: (schema) => schema.required('Both first and last must be provided to search by name'),
        otherwise: (schema) => schema.nullable(),
      }),
  },
  // We need to disable strict mode, so these fields can reference each other
  [['firstName', 'lastName']],
);

export const LOOKUP_PATIENT_QUERY = gql`
  fragment PatientLookupView on Patient {
    id
    nhsNumber
    birthDate
    firstName
    lastName
    numSimilarNames
    gender
    telephone
    selfCare {
      id
      email
    }
    address {
      address
      postcode
    }
    wardAdmission {
      admittedAt
      ward {
        id
        name
      }
      carePathway {
        id
        name
      }
    }
  }

  query LookupPatient($fields: PatientLookupInput!) {
    lookupPatient(fields: $fields) {
      ...PatientLookupView
    }
  }
`;

const isTooManyResultsError = (error: ApolloError | undefined) => {
  if (!error) return false;

  return error.graphQLErrors.some((e) => e.message === 'Lookup returned too many results');
};

const isNotEnoughFieldsError = (error: ApolloError | undefined) => {
  if (!error) return false;

  return error.graphQLErrors.some((e) => e.message === 'Lookup requires at least one field');
};

interface SearchField {
  key: keyof Yup.InferType<typeof searchFormSchema>;
  type: 'text' | 'date';
  label: string;
  placeholder?: string;
  helperText?: string;
  isVisible?: (hasNhsNumberFeature: boolean) => boolean | boolean;
  isPrimary: (hasNhsNumberFeature: boolean) => boolean | boolean;
}

const searchFieldConfig: SearchField[] = [
  {
    key: 'nhsNumber',
    type: 'text',
    label: 'NHS number',
    placeholder: 'e.g. 123 456 7890',
    helperText: "Enter the patient's 10-digit NHS number",
    isVisible: (hasNhsNumberFeature) => hasNhsNumberFeature,
    isPrimary: (hasNhsNumberFeature) => hasNhsNumberFeature,
  },
  {
    key: 'firstName',
    type: 'text',
    label: 'First Name',
    isPrimary: (hasNhsNumberFeature) => !hasNhsNumberFeature,
  },
  {
    key: 'lastName',
    type: 'text',
    label: 'Last Name',
    isPrimary: (hasNhsNumberFeature) => !hasNhsNumberFeature,
  },
  {
    key: 'birthDate',
    type: 'date',
    label: 'Date of Birth',
    isPrimary: () => true,
  },
];

export function SearchPatientStep() {
  const {
    handleStep,
    updateJourneyState,
    currentJourneyState: { patientSearchForm },
    gotoNextStep,
    setIsSubmitting,
  } = useAdmitPatientJourneyContext();

  const hasNhsNumberFeature = useMeActingOrganizationFeature('nhsNumberRetrieval', false);

  const [lookupPatient, { loading, error }] = useLookupPatientLazyQuery({
    onCompleted: (data) => {
      updateJourneyState({
        patient: data.lookupPatient,
        patientSearchForm: formik.values,
      });

      gotoNextStep();
    },
    onError: (error) => {
      // Lets open the additional fields if there are too many results, or not enough fields filled.
      // We don't want to show the toast, since we have guidance for the user in the form
      if (isTooManyResultsError(error) || isNotEnoughFieldsError(error)) {
        setShowAllFields(true);
        return;
      }

      console.log(JSON.stringify(error));

      toast.error('Error looking up patient');
    },
  });

  // Synchronise journey loading state
  useEffect(() => {
    setIsSubmitting(loading);
  }, [loading, setIsSubmitting]);

  // If the user has already submitted the form with additional fields, we want to show them
  // if they go back to this step
  const alreadySubmittedWithAdditionalFields = Object.keys(patientSearchForm ?? {}).some((key) =>
    searchFieldConfig.some((field) => field.key === key && !field.isPrimary(hasNhsNumberFeature)),
  );

  // Initialise to open if the previous submission included additional fields
  // Also if there are too many results
  const [showAllFields, setShowAllFields] = useState(alreadySubmittedWithAdditionalFields);

  const formik = useFormik({
    initialValues: {
      nhsNumber: patientSearchForm?.nhsNumber ?? '',
      birthDate: patientSearchForm?.birthDate ?? '',
      firstName: patientSearchForm?.firstName ?? '',
      lastName: patientSearchForm?.lastName ?? '',
    },
    validationSchema: searchFormSchema,
    onSubmit: (values) => {
      lookupPatient({
        variables: {
          fields: {
            nhsNumber: values?.nhsNumber?.replace(/\s/g, ''),
            birthDate: values.birthDate,
            firstName: values.firstName?.trim(),
            lastName: values.lastName?.trim(),
          },
        },
      });
    },
  });

  handleStep(async () => {
    // We don't want the step to advance until the form is submitted
    return false;
  });

  /**
   * Split the fields into primary and additional fields, based on the config
   */
  const { primaryFields, additionalFields } = useMemo(() => {
    const filteredConfig = searchFieldConfig.filter((field) => {
      if (field.isVisible) {
        return field.isVisible(hasNhsNumberFeature);
      }
      return true;
    });

    const [primaryFields, additionalFields] = _.partition(filteredConfig, (field) =>
      field.isPrimary(hasNhsNumberFeature),
    );

    return {
      primaryFields,
      additionalFields,
    };
  }, [hasNhsNumberFeature]);

  const { argErrors } = getMutationErrors(error);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getFieldProps = muiFormikGetFieldProps(formik, argErrors, searchFormSchema as any);

  return (
    <div>
      <Box marginBottom={2}>
        <Typography variant="body2" color="textSecondary">
          If the patient isn&apos;t already registered, you&apos;ll be able to add them in the next
          step.
        </Typography>
      </Box>
      {isTooManyResultsError(error) && (
        <Alert severity="error">
          <AlertTitle>Search criteria matched more than one patient</AlertTitle>
          Please enter more details to narrow the results to a single patient
        </Alert>
      )}
      <form onSubmit={formik.handleSubmit} id="patientSearchForm" noValidate>
        <Grid container spacing={2} marginTop={2}>
          {primaryFields.map((field, i) => (
            <Grid item xs={12} sm={6} key={field.key}>
              <TextField
                autoFocus={i === 0}
                fullWidth
                InputLabelProps={{ shrink: true }}
                type={field.type}
                variant="outlined"
                {...getFieldProps(field.key, { defaultHelperText: field.helperText })}
                label={field.label}
                placeholder={field.placeholder}
              />
            </Grid>
          ))}
          {!showAllFields && additionalFields.length > 0 && (
            <Grid item xs={12}>
              <MuiLink
                variant="body2"
                underline="none"
                color="primary.main"
                {...createTapProps({ onTap: () => setShowAllFields(true) })}
                sx={{ cursor: 'pointer' }}>
                Show additional search fields
              </MuiLink>
            </Grid>
          )}
          {showAllFields && additionalFields.length > 0 && (
            <>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  Provide additional details to locate the patient
                </Typography>
              </Grid>
              {additionalFields.map((field, i) => (
                <Grid item xs={12} sm={6} key={field.key}>
                  <TextField
                    autoFocus={i === 0}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    {...getFieldProps(field.key, { defaultHelperText: field.helperText })}
                    label={field.label}
                    placeholder={field.placeholder}
                  />
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </form>
    </div>
  );
}
