import React, { useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import makeStyles from '@mui/styles/makeStyles';

import Tooltip from '@mui/material/Tooltip';
import NhsNumberResponseDetailsDialog from './NhsNumberResponseDetailsDialog';
import { GraphQLNhsNumberResponseDetails } from '@/controllers/types';

export const formatNhsNumber = (nhsNumber: string | null | undefined) => {
  if (nhsNumber && nhsNumber.length === 10) {
    const firstThree = nhsNumber.substring(0, 3);
    const middleThree = nhsNumber.substring(3, 6);
    const lastFour = nhsNumber.substring(6, 10);
    return `${firstThree} ${middleThree} ${lastFour}`;
  } else {
    return nhsNumber;
  }
};

const useStyles = makeStyles((theme) => ({
  nhsNumber: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  infoIcon: {
    cursor: 'pointer',
    padding: theme.spacing(0, 1),
    lineHeight: 0,
  },
}));

interface NhsNumberProps {
  nhsNumber: string | null | undefined;
  /**
   * If this is not set it will be fetched when opening the details dialog
   */
  nhsNumberResponseDetails?: GraphQLNhsNumberResponseDetails | null | undefined;
  patientId: string;
  showDetailsIcon?: 'always' | 'hover';
}

export default function NhsNumber({
  nhsNumber,
  patientId,
  nhsNumberResponseDetails,
  showDetailsIcon = 'always',
}: NhsNumberProps) {
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const classes = useStyles();

  const [isHovered, setIsHovered] = useState(false);

  const showNhsNumberResponseDetailsDialog = () => {
    setShowDetailsDialog(true);
  };

  const closeNhsNumberResponseDetailsDialog = () => {
    setIsHovered(false);
    setShowDetailsDialog(false);
  };

  const shouldShowDetailsIcon =
    showDetailsIcon === 'always' || (showDetailsIcon === 'hover' && isHovered);

  const formattedNhsNumber = formatNhsNumber(nhsNumber);
  return (
    <div
      onClick={(event) => {
        event.stopPropagation(); // this is needed for the parent patient row to be clickable in Manage Patients
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <div className={classes.nhsNumber}>
        <div>{formattedNhsNumber ? formattedNhsNumber : <span>&mdash;</span>}</div>
        {shouldShowDetailsIcon && (
          <div className={classes.infoIcon}>
            <Tooltip title="Show details">
              <InfoIcon
                role="button"
                aria-label="Show NHS number details"
                fontSize="small"
                onClick={showNhsNumberResponseDetailsDialog}
              />
            </Tooltip>
          </div>
        )}
      </div>
      <NhsNumberResponseDetailsDialog
        open={showDetailsDialog}
        onClose={closeNhsNumberResponseDetailsDialog}
        formattedNhsNumber={formattedNhsNumber}
        nhsNumberResponseDetails={nhsNumberResponseDetails}
        patientId={patientId}
      />
    </div>
  );
}
