import React, { useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Collapse,
  MenuItem,
  Paper,
  Popover,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { gql } from '@apollo/client';
import { toast } from 'sonner';
import { Link as RouterLink } from 'react-router-dom';
import FilterIcon from '@mui/icons-material/FilterList';

import {
  usePatientNotesQuery,
  useAddPatientNoteMutation,
  PatientNoteFragment,
  FormattableUserFragment,
  User,
} from '@/generated/graphql';

import Loading from '@/components/Loading';
import { ErrorDisplay } from '@/components/ErrorDisplay';
import { isDefined } from '@/helpers/isDefined';
import { useAuthMe, useMePermission } from '@/hooks/useAuth';
import { UserName } from '@/components/UserName';
import { useDebounce } from '@/hooks/useDebounce';
import { withStyles } from '@mui/styles';
import { formatUserName, PartialOrganization } from '@/helpers/formatUserName';

export const NOTES_FRAGMENT = gql`
  fragment PatientNote on PatientNote {
    id
    createdAt
    text
    createdBy {
      id
      ...FormattableUser
    }
    organization {
      id
      name
    }
    checkup {
      id
    }
  }
`;

export const QUERY_PATIENT_NOTES = gql`
  query PatientNotes($PatientId: ID!) {
    patient(id: $PatientId) {
      id
      createdAt
      firstName
      lastName
      notes {
        ...PatientNote
      }
      selfCare {
        id
      }
    }
  }
`;

export const ADD_PATIENT_NOTE = gql`
  mutation AddPatientNote($PatientId: ID!, $text: String!) {
    addPatientNote(PatientId: $PatientId, text: $text) {
      id
    }
  }
`;

const INITIAL_FILTERS = {
  body: null as string | null,
  createdByText: null as string | null,
  organizationId: null as string | null,
  type: 'all' as 'checkup' | 'all',
} as const;

type Filters = typeof INITIAL_FILTERS;

export interface AddNoteFormValues {
  text: string;
}

interface PatientNotesTabProps {
  patientId: string;
}

const memoizedNames = new Map<string, string>();
/**
 * Memoized version of formatUserName.
 * This is used to prevent reformatting the same user multiple times when filtering notes.
 */
const memoizedFormatUserName = (
  user: Maybe<FormattableUserFragment & Pick<User, 'id'>>,
  actingOrganization: PartialOrganization,
) => {
  if (!isDefined(user)) return undefined;

  if (memoizedNames.has(user.id)) {
    return memoizedNames.get(user.id) as string;
  }

  const formattedName = formatUserName(user, actingOrganization);
  memoizedNames.set(user.id, formattedName);
  return formattedName;
};

const bodyFilter = (value: string, note: PatientNoteFragment) =>
  note.text.toLocaleLowerCase().includes(value.toLocaleLowerCase());
const createdByFilter = (value: string, note: PatientNoteFragment) => {
  return (
    memoizedFormatUserName(note.createdBy, note.organization)
      ?.toLocaleLowerCase()
      ?.includes(value.toLocaleLowerCase()) ?? false
  );
};
const organizationFilter = (organizationId: string | null, note: PatientNoteFragment) =>
  note.organization?.id === organizationId;

const typeFilter = (type: Filters['type'], note: PatientNoteFragment) => {
  switch (type) {
    case 'checkup':
      return isDefined(note.checkup);
    default:
      return true;
  }
};

export default function PatientNotesTab({ patientId }: PatientNotesTabProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const canAddPatientNotes = useMePermission('add_patient_notes');

  const schema = Yup.object().shape({
    text: Yup.string().required('Text is required').min(1, 'Text is required'),
  });

  const actingOrganization = useAuthMe<{ id: string; name: string }>('actingOrganization');

  // Clear the memoized names when the component is unmounted (just to clear up memory)
  useEffect(() => {
    () => memoizedNames.clear();
  }, []);

  const [isInputFocused, setIsInputFocused] = useState(false);

  const {
    data,
    loading: isLoadingNotes,
    error: fetchError,
    refetch,
  } = usePatientNotesQuery({
    variables: { PatientId: patientId },
  });

  const notes = useMemo(() => data?.patient.notes ?? [], [data]);

  const [filters, setFilters] = useState(INITIAL_FILTERS);

  const debouncedFilters = useDebounce(filters, 500);

  /**
   * Represents an array of all organizations associated with the patient notes.
   * Each organization object contains an id and a name.
   */
  const allOrganizations = useMemo(() => {
    // If the acting organization is not null, add it to the list of organizations
    const actingOrgFilterValue = actingOrganization
      ? { [actingOrganization.id]: `${actingOrganization.name} (My Organisation)` }
      : {};

    const mapped = notes.reduce(
      (acc, note) => {
        if (note.organization && !acc[note.organization.id]) {
          acc[note.organization.id] = note.organization.name;
        }
        return acc;
      },
      { ...actingOrgFilterValue } as Record<string, string>,
    );

    return Object.entries(mapped).map(([id, name]) => ({ id, name }));
  }, [notes, actingOrganization]);

  const filteredNotes = useMemo(() => {
    return notes.filter((note) => {
      /**
       * Note: These are performed in order of most performant to least performant,
       * this prevents us from running the most expensive checks first.
       */
      return (
        (!debouncedFilters.organizationId ||
          organizationFilter(debouncedFilters.organizationId, note)) &&
        typeFilter(debouncedFilters.type, note) &&
        (!debouncedFilters.body || bodyFilter(debouncedFilters.body, note)) &&
        (!debouncedFilters.createdByText || createdByFilter(debouncedFilters.createdByText, note))
      );
    });
  }, [notes, debouncedFilters]);

  const [addNoteMutation, { loading: isSubmitting }] = useAddPatientNoteMutation({
    onCompleted: () => {
      formik.resetForm();
      refetch();
    },
    onError: () => toast.error("An error occurred when adding the note to the patient's record"),
  });

  const handleSubmit = async (values: AddNoteFormValues) => {
    await addNoteMutation({
      variables: {
        PatientId: patientId,
        text: values.text,
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      text: '',
    },
    validationSchema: schema,
    onSubmit: handleSubmit,
    validateOnMount: true,
    validateOnBlur: true,
  });

  if (fetchError) {
    return (
      <ErrorDisplay
        message="Failed to fetch patient notes. Press retry to try again."
        retry={refetch}
        showIcon
      />
    );
  }

  return (
    <Box display="flex" justifyContent="center">
      <Box margin={3} flex="1 1 auto" maxWidth={1080}>
        <Stack gap={1} justifyContent="flex-start" alignItems="flex-start" marginBottom={2}>
          <FilterButton
            filters={filters}
            organizationOptions={allOrganizations}
            setFilters={setFilters}
            resetFilters={() => setFilters(INITIAL_FILTERS)}
          />
          <FilterTags
            filters={filters}
            removeFilter={(key) => setFilters({ ...filters, [key]: null })}
            organizationOptions={allOrganizations}
          />
        </Stack>
        {canAddPatientNotes ? (
          <Paper
            elevation={0}
            component="form"
            onSubmit={formik.handleSubmit}
            className={classes.form}>
            <TextField
              fullWidth
              multiline
              name="text"
              placeholder="Type your note here"
              variant="standard"
              required
              value={formik.values.text}
              onChange={formik.handleChange}
              disabled={isLoadingNotes || isSubmitting}
              inputProps={{
                'aria-label': 'Type your note here',
              }}
              InputProps={{
                disableUnderline: true,
                'aria-label': 'Type your note here',
              }}
              onFocus={() => setIsInputFocused(true)}
              onBlur={() => setIsInputFocused(false)}
              onKeyUp={(e) => {
                if (e.key === 'Escape') {
                  formik.resetForm();
                  (e.target as HTMLInputElement).blur();
                }
              }}
            />
            <Collapse in={formik.dirty || isInputFocused}>
              <div className={classes.submitButtonWrap}>
                <Button
                  type="reset"
                  onClick={async () => {
                    formik.resetForm();
                  }}
                  size="small"
                  disableElevation
                  disabled={isLoadingNotes || isSubmitting || !formik.isValid}>
                  Clear
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="small"
                  disableElevation
                  disabled={isLoadingNotes || isSubmitting || !formik.isValid}>
                  Save note
                </Button>
              </div>
            </Collapse>
          </Paper>
        ) : null}
        <Box marginTop={2}>
          {isLoadingNotes && data === undefined ? (
            <Loading showLoading />
          ) : (
            <ul className={classes.noteList}>
              {!isLoadingNotes && filteredNotes.length === 0 ? (
                <>
                  {notes.length === 0 ? (
                    <Typography className={classes.noNotesText}>
                      No notes logged for this patient
                    </Typography>
                  ) : (
                    <Stack gap={2} justifyContent="center" alignItems="center">
                      <Typography className={classes.noNotesText}>
                        No notes match the current filters
                      </Typography>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setFilters(INITIAL_FILTERS)}>
                        Clear filters
                      </Button>
                    </Stack>
                  )}
                </>
              ) : (
                filteredNotes.map((note) => (
                  <Paper
                    component="li"
                    className={classes.noteItem}
                    key={note.id}
                    elevation={2}
                    role="article"
                    aria-labelledby={`note-text-${note.id}`}>
                    <span className={classes.noteHeader}>
                      <time className={classes.noteDate} dateTime={note.createdAt}>
                        {t('DATETIME_LONG', {
                          val: new Date(note.createdAt),
                        }).toString()}
                      </time>
                      <span>
                        {isDefined(note.checkup) && (
                          <Chip
                            className={classes.checkupLink}
                            component={RouterLink}
                            to={`/patient/${patientId}/checkup/${note.checkup.id}`}
                            label="Linked to Check-up"
                            size="small"
                            color="primary"
                          />
                        )}
                      </span>
                    </span>
                    <Typography className={classes.noteText} id={`note-text-${note.id}`}>
                      {note.text}
                    </Typography>
                    <div className={classes.reporterAndShowMore}>
                      <span className={classes.reporter}>
                        <UserName
                          user={note.createdBy}
                          userActingOrganization={note.organization}
                          preFormattedName={memoizedFormatUserName(
                            note.createdBy,
                            note.organization,
                          )}
                        />
                      </span>
                    </div>
                  </Paper>
                ))
              )}
            </ul>
          )}
        </Box>
      </Box>
    </Box>
  );
}

interface FilterButtonProps {
  filters: Filters;
  setFilters: (filters: Filters) => void;
  resetFilters: () => void;
  organizationOptions: { id: string; name: string }[];
}

function FilterButton({
  filters,
  setFilters,
  resetFilters,
  organizationOptions,
}: FilterButtonProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  function setFilter<TKey extends keyof Filters>(key: TKey, value: Filters[TKey] | null) {
    setFilters({ ...filters, [key]: value });
  }

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        endIcon={<FilterIcon />}
        onClick={handleButtonClick}
        sx={{ backgroundColor: anchorEl ? 'common.white' : 'transparent' }}>
        Filter
      </Button>
      <Popover
        sx={{ marginTop: 2 }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <Box
          display="flex"
          flexDirection="column"
          padding={2}
          minWidth={{ xs: 300, sm: 500 }}
          maxWidth={500}
          width="100%">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography
              sx={{
                fontWeight: 500,
                color: 'primary.dark',
              }}>
              Filters
            </Typography>
            <Button variant="text" color="primary" size="small" onClick={() => resetFilters()}>
              Reset Filters
            </Button>
          </Box>
          <Stack gap={2} marginTop={2}>
            <TextField
              value={filters.body ?? ''}
              onChange={(e) => setFilter('body', e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setAnchorEl(null);
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              label="Note text"
              variant="outlined"
              placeholder="Note text"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              select
              variant="outlined"
              value={filters.type ?? 'all'}
              onChange={(e) => setFilter('type', e.target.value as Filters['type'] | null)}
              label="Type"
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}>
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="checkup">Linked to Check-up</MenuItem>
            </TextField>
            <TextField
              value={filters.createdByText ?? ''}
              onChange={(e) => setFilter('createdByText', e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setAnchorEl(null);
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              label="Author"
              variant="outlined"
              placeholder="Author"
              InputLabelProps={{ shrink: true }}
            />
            <Autocomplete
              options={organizationOptions}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, value) => setFilter('organizationId', value?.id ?? null)}
              value={organizationOptions.find((o) => o.id === filters.organizationId) ?? null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Organization"
                  placeholder="Organization"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Stack>
        </Box>
      </Popover>
    </>
  );
}

interface FilterTagsProps {
  filters: Filters;
  removeFilter: (key: keyof Filters) => void;
  organizationOptions: { id: string; name: string }[];
}

export function FilterTags({ filters, removeFilter, organizationOptions }: FilterTagsProps) {
  return (
    <Box display="flex">
      {filters.organizationId && (
        <FilterChip
          color="primary"
          label={`Organisation: ${
            organizationOptions.find((o) => o.id === filters.organizationId)?.name
          }`}
          onDelete={() => removeFilter('organizationId')}
        />
      )}
      {filters.body && (
        <FilterChip
          color="primary"
          label={`Text: ${filters.body}`}
          onDelete={() => removeFilter('body')}
        />
      )}
      {filters.type && filters.type !== 'all' && (
        <FilterChip
          color="primary"
          label={filters.type === 'checkup' ? 'Linked to Check-up' : 'All'}
          onDelete={() => removeFilter('type')}
        />
      )}
      {filters.createdByText && (
        <FilterChip
          color="primary"
          label={`Reporter: ${filters.createdByText}`}
          onDelete={() => removeFilter('createdByText')}
        />
      )}
    </Box>
  );
}

const FilterChip = withStyles((theme) => ({
  root: {
    marginRight: theme.spacing(1),
    color: theme.palette.common.white,
    fontWeight: 500,
    height: 28,
  },
}))(Chip);

const useStyles = makeStyles((theme) => ({
  noteList: {
    margin: 0,
    padding: 0,
  },
  noteItem: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(3, 0),
    padding: theme.spacing(2),
  },
  noteHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  noteDate: {
    fontWeight: 500,
    color: theme.palette.grey[800],
  },
  noteText: {
    margin: theme.spacing(1.5, 0),
  },
  form: {
    padding: theme.spacing(2),
    width: '100%',
  },
  reporterAndShowMore: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'flex-end',
  },
  reporter: {
    fontWeight: 500,
    color: theme.palette.grey[800],
  },
  submitButtonWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  noNotesText: {
    textAlign: 'center',
    marginTop: theme.spacing(3),
    color: theme.palette.grey[600],
    fontSize: theme.typography.h6.fontSize,
  },
  checkupLink: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));
