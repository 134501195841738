/**
 * A helper to encapsulate our posthog client for the future where we may want to customise it
 * further.
 *
 * NOTE: When using posthog.capture(...) we follow the naming conventions outlined
 *       here: https://posthog.com/product-engineers/5-ways-to-improve-analytics-data#1-implement-a-naming-convention
 */
import posthog from 'posthog-js';

export function initialise() {
  // We do not want to capture PostHog product analytics when running locally, or during e2e tests
  // running in GitHub actions.
  //
  // NOTE: The posthog "web" client does not have a disabled option, like the react-native and node
  //       clients do.
  //       see: https://posthog.com/docs/product-analytics/installation#option-2-install-via-package-manager
  const telemetryEnabled =
    window.location.hostname !== 'localhost' && window.location.hostname !== '127.0.0.1';

  if (telemetryEnabled) {
    posthog.init(
      process.env.REACT_APP_FEEBRIS_ENVIRONMENT?.startsWith('prod-')
        ? 'phc_gmmtD4Tb8bXHBKJN9PDBBMMmQLsr9oFenmiqGEswhV'
        : 'phc_tAO3kcDMqqzHC4iH9qnG7PF8vGXMbPUOj8hnMOEaRrZ',
      {
        api_host: 'https://eu.i.posthog.com',
        // Only capture events for logged in users
        person_profiles: 'identified_only',
      },
    );
  }
}

export default posthog;
