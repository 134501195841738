import React, { useEffect, useRef, useState } from 'react';

import _ from 'lodash';
import { Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import EngineerIcon from '@mui/icons-material/Engineering';
import makeStyles from '@mui/styles/makeStyles';
import { toast } from 'sonner';
import { formatDistance } from 'date-fns';
import { useEvent } from 'react-use';

const FETCH_EVERY = 60 * 1000;
const ONE_DAY = 24 * 60 * 60 * 1000;
const ABUNDANCE_OF_CAUTION = 60 * 1000;

interface MaintenanceInfo {
  title: string;
  message: string;
  startAt: Date;
}

export default function MaintenanceBanner() {
  const visible = useWindowVisible();
  const [maintenance, setMaintenance] = useState<MaintenanceInfo>();

  const refresh = async (): Promise<void> => {
    let response: Response | undefined;
    try {
      response = await fetch(`${process.env.REACT_APP_API}/maintenance`, {
        headers: {
          'Feebris-Agent': `Feebris/${process.env.REACT_APP_VERSION}(portal)`,
          'Content-Type': 'application/json; charset=utf-8',
        },
      });
    } catch (error) {
      // Ignore all errors on this fetch
    }

    let newMaintenance = undefined;
    if (response && response.json) {
      let data;
      try {
        data = await response.json();
        if (data.title && data.message && data.startAt) {
          newMaintenance = {
            title: data.title,
            message: data.message,
            startAt: new Date(data.startAt),
          };
        }
      } catch {
        // Any parse errors, bail
      }
    }

    if (!newMaintenance || !_.isEqual(newMaintenance, maintenance)) {
      setMaintenance(newMaintenance);
    }
  };

  // Fetch periodically, but only when the browser window is visible
  useInterval(() => refresh(), visible ? FETCH_EVERY : null);

  useEffect(() => {
    // But also fetch right now on component mount
    if (visible) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  // NB: This code will run on first component render and each time the maintenance state is
  //     changed. That means if you increase the FETCH_EVERY constant then this code will run less
  //     frequently, which might mean that the MaintenanceReminderToast stays on the screen for too
  //     long, or MaintenanceDialogPageBlocker stays on the screen for longer than you expect.
  //     The advantage of this approach is the MaintenanceBanner (and children) should not be likely
  //     to cause performance issues on the page (since most of the time they are unused).
  let reminderToastOpen = false;
  let dialogPageBlockerOpen = false;

  if (maintenance) {
    const delta = maintenance.startAt.getTime() - ABUNDANCE_OF_CAUTION - Date.now();
    reminderToastOpen = delta > 0 && delta < ONE_DAY;
    dialogPageBlockerOpen = delta < 0;
  }

  return (
    <>
      <MaintenanceReminderToast maintenance={maintenance} open={reminderToastOpen} />
      <MaintenanceDialogPageBlocker maintenance={maintenance} open={dialogPageBlockerOpen} />
    </>
  );
}

/**
 * When `open` is `true` will display an omnipresent toast message about the
 * maintenance work that is happening soon. The toast message will update it's text every
 * 30s to ensure the date in the message is fresh.
 */
function MaintenanceReminderToast({
  open,
  maintenance,
}: {
  open: boolean;
  maintenance: MaintenanceInfo | undefined;
}) {
  useEffect(() => {
    const toastId = 'maintenance-banner';
    let timer: ReturnType<typeof setInterval>;

    if (open && maintenance) {
      // Makes a string like "about 2 hours"
      const friendlyDelta = formatDistance(new Date(), maintenance.startAt);

      toast.warning(`Feebris will be undergoing essential improvement works in ${friendlyDelta}`, {
        // We use a fixed ID so that we can update the toast message without creating a new toast
        id: toastId,
        icon: <EngineerIcon />,
        dismissible: false,
        position: 'bottom-left',
        duration: Infinity,
      });

      // Every 30 seconds refresh the toast message
      timer = setInterval(() => {
        const friendlyDelta = formatDistance(new Date(), maintenance.startAt);
        toast.warning(
          `Feebris will be undergoing essential improvement works in ${friendlyDelta}`,
          { id: toastId },
        );
      }, 30_000);
    }

    return () => {
      toast.dismiss(toastId);
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return null;
}

/**
 * When `open` is `true` will render a full-page blocker Dialog about the maintenance
 * work that is currently happening. The user will be prevented from doing any page actions.
 */
function MaintenanceDialogPageBlocker({
  open,
  maintenance,
}: {
  open: boolean;
  maintenance: MaintenanceInfo | undefined;
}) {
  const classes = useStyles();
  return open ? (
    <Dialog
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ className: classes.dialog }}>
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        {maintenance?.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className={classes.message}>
          {/* TODO: Need to handle message formatting: bold and hyperlink (basic templating / markdown?) */}
          {maintenance?.message}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  ) : null;
}

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: '#F9E1A7',
    borderColor: '#D39A61',
    borderTop: `6px solid ${theme.palette.warning.dark}`,
  },
  title: {
    color: '#433b14',
  },
  message: {
    whiteSpace: 'pre-line',
    color: '#433b14',
  },
}));

function useWindowVisible() {
  const [visible, setVisible] = useState(true);

  const onVisibilityChange = ({ target }: Event) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setVisible((target && !(target as any)['hidden']) ?? false);

  useEvent('visibilitychange', onVisibilityChange, window);

  return visible;
}

function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (delay === null) {
      return;
    }

    const id = setInterval(() => {
      savedCallback.current();
    }, delay);

    return () => {
      clearInterval(id);
    };
  }, [delay]);
}
