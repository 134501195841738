import React, { ReactNode, useEffect } from 'react';
import { Box, Button, Paper, SvgIcon, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { gql } from '@apollo/client';
import { format, formatDistance, formatDistanceToNow, isToday, isYesterday } from 'date-fns';
import clsx from 'clsx';
import * as Sentry from '@sentry/browser';

import HotelIcon from '@mui/icons-material/Hotel';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PhotoIcon from '@mui/icons-material/Photo';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import BarChartIcon from '@mui/icons-material/BarChart';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';

import {
  AcceptedTermsInstanceFragment,
  ActionLogItemFragment,
  AlertInstanceFragment,
  AlertRuleInstanceFragment,
  AlertType,
  CarePathwayInstanceFragment,
  CheckupConfigInstanceFragment,
  CheckupInstanceFragment,
  CheckupScheduleEventInstanceFragment,
  CheckupScheduleInstanceFragment,
  CheckupTypeInstanceFragment,
  ContinuousMonitoringInstanceFragment,
  ContinuousMonitoringSessionInstanceFragment,
  MessageEntityContentFragment,
  NhsNumberInstanceFragment,
  OrganizationInstanceFragment,
  OrganizationPatientInstanceFragment,
  PatientInstanceFragment,
  PatientNoteInstanceFragment,
  EwsThresholdsInstanceFragment,
  PictureInstanceFragment,
  UserInstanceFragment,
  UserOrganizationInstanceFragment,
  WardInstanceFragment,
  useGetActionLogsQuery,
  EhrIntegrationEventInstanceFragment,
  EhrIntegrationInstanceFragment,
  EhrIntegrationIdentityInstanceFragment,
  PacsanaSessionInstanceFragment,
  PacsanaEventInstanceFragment,
  IntegrationApiKeyInstanceFragment,
  ShareTokenInstanceFragment,
} from '@/generated/graphql';

import feebrisDateFormatter from '@/helpers/LocaleFormatting';

import Loading from '@/components/Loading';
import { isDefined } from '@/helpers/isDefined';
import { ErrorDisplay } from '@/components/ErrorDisplay';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import {
  formatIdentityType,
  formatIntegrationTriggerType,
  formatIntegrationType,
} from '@/components/EhrIntegrations/format';
import { useTranslation } from 'react-i18next';
import { UserName } from '@/components/UserName';
import { formatUserName } from '@/helpers/formatUserName';

export const QUERY_PATIENT_ACTION_LOGS = gql`
  fragment acceptedTermsInstance on AcceptedTerms {
    acceptedTermsId: id
  }

  fragment alertInstance on Alert {
    alertRule {
      ...alertRuleInstance
    }
    alertType: type
  }

  fragment alertRuleInstance on AlertRule {
    alertRuleName: name
    alertRuleType: type
  }

  fragment carePathwayInstance on CarePathway {
    carePathwayName: name
  }

  fragment checkupInstance on Checkup {
    checkupType: type
  }

  fragment checkupConfigInstance on CheckupConfig {
    checkupConfigName: name
  }

  fragment checkupScheduleInstance on CheckupSchedule {
    checkupScheduleId: id
  }

  fragment checkupScheduleEventInstance on CheckupScheduleEvent {
    checkupExpectedAt
  }

  fragment checkupTypeInstance on CheckupType {
    checkupTypeName: name
  }

  fragment continuousMonitoringInstance on ContinuousMonitoring {
    continousMonitoringId: id
  }

  fragment continuousMonitoringSessionInstance on ContinuousMonitoringSession {
    sessionCreatedAt: createdAt
    sessionEndedAt: endedAt
  }

  fragment pacsanaSessionInstance on PacsanaSession {
    sessionCreatedAt: createdAt
    sessionEndedAt: endedAt
  }

  fragment pacsanaEventInstance on PacsanaEvent {
    eventName
  }

  fragment ehrIntegrationInstance on EhrIntegration {
    integrationType
    triggerType
    triggerConfig {
      ...FormattableIntegrationTriggerConfig
    }
  }

  fragment ehrIntegrationEventInstance on EhrIntegrationEvent {
    integration {
      ...ehrIntegrationInstance
    }

    summaryPeriod {
      from
      to
    }
  }

  fragment ehrIntegrationIdentityInstance on EhrIntegrationIdentity {
    identityType
  }

  fragment organizationInstance on Organization {
    organizationName: name
  }

  fragment organizationPatientInstance on OrganizationPatient {
    patient {
      ...patientInstance
    }

    organization {
      ...organizationInstance
    }
  }

  fragment nhsNumberInstance on NhsNumber {
    nhsNumberId: id
  }

  fragment patientInstance on Patient {
    patientId: id
    patientFirstName: firstName
    patientLastName: lastName
  }

  fragment patientNoteInstance on PatientNote {
    patientNoteText: text
  }

  fragment ewsThresholdsInstance on EWSThresholds {
    ewsThresholdsId: id
  }

  fragment integrationApiKeyInstance on IntegrationApiKey {
    integrationApiKeyId: id
  }

  fragment pictureInstance on Picture {
    pictureId: id
    caption
  }

  fragment shareTokenInstance on ShareToken {
    shareTokenId: id
    shareTokenCreatedAt: createdAt
    shareTokenExpiresAt: expiresAt
  }

  fragment userInstance on User {
    userFirstName: firstName
    userLastName: lastName
    email
  }

  fragment userOrganizationInstance on UserOrganization {
    user {
      ...userInstance
    }
    roles
  }

  fragment wardInstance on Ward {
    wardName: name
  }

  fragment messageEntityContent on MessageEntity {
    text
    type
    instance {
      ...acceptedTermsInstance
      ...alertInstance
      ...alertRuleInstance
      ...carePathwayInstance
      ...checkupInstance
      ...checkupConfigInstance
      ...checkupScheduleInstance
      ...checkupScheduleEventInstance
      ...checkupTypeInstance
      ...continuousMonitoringSessionInstance
      ...pacsanaSessionInstance
      ...pacsanaEventInstance
      ...organizationInstance
      ...organizationPatientInstance
      ...nhsNumberInstance
      ...patientInstance
      ...patientNoteInstance
      ...ewsThresholdsInstance
      ...ehrIntegrationInstance
      ...ehrIntegrationEventInstance
      ...ehrIntegrationIdentityInstance
      ...integrationApiKeyInstance
      ...pictureInstance
      ...shareTokenInstance
      ...userInstance
      ...userOrganizationInstance
      ...wardInstance
    }
  }

  fragment ActionLogItem on ActionLog {
    id
    message
    createdAt
    user {
      ...FormattableUser
    }
    affectedOrganization {
      id
      name
    }
    loggedInAs {
      id
    }
    messageEntities {
      ...messageEntityContent
    }
  }

  query GetActionLogs($patientId: ID!, $fromActionLogId: ID) {
    actionLogs(patientId: $patientId, fromActionLogId: $fromActionLogId) {
      logs {
        ...ActionLogItem
      }
      nextActionLogId
    }
  }
`;

interface ActionLogTab {
  patientId: string;
}

export default function ActionLogTab({ patientId }: ActionLogTab) {
  const classes = useStyles();

  const { data, loading, error, refetch, fetchMore } = useGetActionLogsQuery({
    variables: { patientId: patientId, fromActionLogId: null },
  });

  const hasMore = isDefined(data?.actionLogs?.nextActionLogId);

  const handleMoreClick = () => {
    fetchMore({
      variables: {
        fromActionLogId: data?.actionLogs?.nextActionLogId,
        patientId,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (isDefined(fetchMoreResult?.actionLogs) === false) {
          return prev;
        }

        return {
          actionLogs: {
            logs: [...prev.actionLogs.logs, ...fetchMoreResult.actionLogs.logs],
            nextActionLogId: fetchMoreResult.actionLogs.nextActionLogId,
          },
        };
      },
    });
  };

  const actionLogs = data?.actionLogs?.logs;

  return (
    <Box display="flex" justifyContent="center" marginTop={1}>
      <Box margin={3} flex="1 1 auto" maxWidth={1080} role="list">
        {error ? (
          <ErrorDisplay
            showIcon
            message="Failed to fetch action logs from the server. Press retry to try again."
            retry={refetch}
          />
        ) : loading !== true && actionLogs?.length ? (
          <>
            <div className={classes.timelineHead} />
            {actionLogs.map((log) => log && <ActionLogItem key={log.id} log={log} />)}
            {hasMore && (
              <Box display="flex" justifyContent="center" marginTop={2}>
                <Button variant="contained" color="primary" onClick={() => handleMoreClick()}>
                  Show more
                </Button>
              </Box>
            )}
          </>
        ) : (
          <Loading showLoading />
        )}
      </Box>
    </Box>
  );
}

function ActionLogItem({ log }: { log: ActionLogItemFragment }) {
  const classes = useActionLogItemStyles();

  //This variable is what we use to determine if we can safely render all instances within the log.
  //If we can't, we use this to fall back on the raw message.
  const allInstancesRenderable = log.messageEntities?.every(
    (l) =>
      // check that the instance type is mapped
      isDefined(instanceMap[l.type]) &&
      // check that non-text entities have an instance defined
      (l.type == 'text' || (l.type != 'text' && isDefined(l.instance))),
  );

  useEffect(() => {
    if (!allInstancesRenderable) {
      Sentry.captureMessage('An unrenderable action log item was encountered', {
        extra: {
          actionLogId: log.id,
        },
      });
    }
  }, [allInstancesRenderable, log]);

  return (
    <Box role="listitem">
      <Paper elevation={2} className={clsx(classes.root)}>
        <ErrorBoundary
          fallback={
            <Box>
              <Typography>Something went wrong when rendering this action.</Typography>
            </Box>
          }>
          <Box display="flex" alignItems="center">
            <Box
              className={clsx(classes.iconContainer)}
              display="flex"
              alignItems="center"
              justifyContent="center">
              <ActionIcon log={log} />
            </Box>
            <DateSection date={new Date(log.createdAt)} />
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Box className={classes.messageSection} data-testid="action-log-item-message-section">
                {allInstancesRenderable
                  ? log.messageEntities?.map(
                      (entity, index) =>
                        entity && (
                          <React.Fragment key={index}>
                            {instanceMap[entity.type](entity)}
                          </React.Fragment>
                        ),
                    )
                  : log.message}
              </Box>
              {log.loggedInAs ? (
                <Box>Change made by Feebris</Box>
              ) : (
                <Box>
                  <UserName user={log.user} userActingOrganization={log.affectedOrganization} />
                </Box>
              )}
            </Box>
          </Box>
        </ErrorBoundary>
      </Paper>
      <div className={classes.timelineLine}></div>
    </Box>
  );
}

function DateSection({ date }: { date: Date }) {
  const classes = useDateSectionStyles();
  const isDateToday = isToday(date);
  const isDateYesterday = isYesterday(date);

  const { t } = useTranslation();

  const fullDateForTooltip = t('DATETIME_LONG', {
    val: date,
  });

  return (
    <Tooltip
      title={fullDateForTooltip}
      arrow
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -5],
            },
          },
        ],
      }}>
      <Box className={classes.dateSection}>
        {isDateToday === false && (
          <Box>{isDateYesterday ? 'Yesterday' : format(date, 'MMM do yyyy')}</Box>
        )}
        <Box>{isDateToday ? `${formatDistanceToNow(date)} ago` : format(date, 'h:mm a')}</Box>
      </Box>
    </Tooltip>
  );
}

const instanceMap: Record<
  MessageEntityContentFragment['type'],
  (messageEntity: MessageEntityContentFragment) => JSX.Element | null
> = {
  text: (messageEntity) => (messageEntity.text ? <TextInstance text={messageEntity.text} /> : null),
  AcceptedTerms: (messageEntity) => (
    <AcceptedTermsInstance
      acceptedTerms={messageEntity.instance as AcceptedTermsInstanceFragment}
    />
  ),
  Alert: (messageEntity) => (
    <AlertInstance alert={messageEntity.instance as AlertInstanceFragment} />
  ),
  AlertRule: (messageEntity) => (
    <AlertRuleInstance alertRule={messageEntity.instance as AlertRuleInstanceFragment} />
  ),
  CarePathway: (messageEntity) => (
    <CarePathwayInstance carePathway={messageEntity.instance as CarePathwayInstanceFragment} />
  ),
  Checkup: (messageEntity) => (
    <CheckupInstance checkup={messageEntity.instance as CheckupInstanceFragment} />
  ),
  CheckupConfig: (messageEntity) => (
    <CheckupConfigInstance
      checkupConfig={messageEntity.instance as CheckupConfigInstanceFragment}
    />
  ),
  CheckupSchedule: (messageEntity) => (
    <CheckupScheduleInstance
      checkupSchedule={messageEntity.instance as CheckupScheduleInstanceFragment}
    />
  ),
  CheckupScheduleEvent: (messageEntity) => (
    <CheckupScheduleEventInstance
      checkupScheduleEvent={messageEntity.instance as CheckupScheduleEventInstanceFragment}
    />
  ),
  CheckupType: (messageEntity) => (
    <CheckupTypeInstance checkupType={messageEntity.instance as CheckupTypeInstanceFragment} />
  ),
  ContinuousMonitoring: (messageEntity) => (
    <ContinuousMonitoringInstance
      continuousMonitoring={messageEntity.instance as ContinuousMonitoringInstanceFragment}
    />
  ),
  ContinuousMonitoringSession: (messageEntity) => (
    <ContinuousMonitoringSessionInstance
      continuousMonitoringSession={
        messageEntity.instance as ContinuousMonitoringSessionInstanceFragment
      }
    />
  ),
  PacsanaSession: (messageEntity) => (
    <PacsanaSessionInstance
      pacsanaSession={messageEntity.instance as PacsanaSessionInstanceFragment}
    />
  ),
  PacsanaEvent: (messageEntity) => (
    <PacsanaEventInstance pacsanaEvent={messageEntity.instance as PacsanaEventInstanceFragment} />
  ),
  EhrIntegration: (messageEntity) => (
    <EhrIntegrationInstance
      integration={messageEntity.instance as EhrIntegrationInstanceFragment}
    />
  ),
  EhrIntegrationEvent: (messageEntity) => (
    <EhrIntegrationEventInstance
      integrationEvent={messageEntity.instance as EhrIntegrationEventInstanceFragment}
    />
  ),
  EhrIntegrationIdentity: (messageEntity) => (
    <EhrIntegrationIdentityInstance
      identity={messageEntity.instance as EhrIntegrationIdentityInstanceFragment}
    />
  ),
  NhsNumber: (messageEntity) => (
    <NhsNumberInstance nhsNumber={messageEntity.instance as NhsNumberInstanceFragment} />
  ),
  Organization: (messageEntity) => (
    <OrganizationInstance organization={messageEntity.instance as OrganizationInstanceFragment} />
  ),
  OrganizationPatient: (messageEntity) => {
    const orgPatient = messageEntity.instance as OrganizationPatientInstanceFragment;

    return (
      <>
        link to <OrganizationInstance organization={orgPatient.organization} /> for{' '}
        <PatientInstance patient={orgPatient.patient} />
      </>
    );
  },
  Patient: (messageEntity) => (
    <PatientInstance patient={messageEntity.instance as PatientInstanceFragment} />
  ),
  PatientNote: (messageEntity) => (
    <PatientNoteInstance patientNote={messageEntity.instance as PatientNoteInstanceFragment} />
  ),
  EWSThresholds: (messageEntity) => (
    <EWSThresholdsInstance
      ewsThresholds={messageEntity.instance as EwsThresholdsInstanceFragment}
    />
  ),
  IntegrationApiKey: (messageEntity) => (
    <IntegrationApiKeyInstance
      integrationApiKey={messageEntity.instance as IntegrationApiKeyInstanceFragment}
    />
  ),
  Picture: (messageEntity) => (
    <PictureInstance picture={messageEntity.instance as PictureInstanceFragment} />
  ),
  ShareToken: (messageEntity) => (
    <ShareTokenInstance shareToken={messageEntity.instance as ShareTokenInstanceFragment} />
  ),
  User: (messageEntity) => <UserInstance user={messageEntity.instance as UserInstanceFragment} />,
  UserOrganization: (messageEntity) => (
    <>
      organization{' '}
      <UserInstance user={(messageEntity.instance as UserOrganizationInstanceFragment).user} />
    </>
  ),
  Ward: (messageEntity) => <WardInstance ward={messageEntity.instance as WardInstanceFragment} />,
};

const TextInstance = ({ text }: { text: string }) => <span>{text}</span>;

const AcceptedTermsInstance = (_: { acceptedTerms: AcceptedTermsInstanceFragment }) => (
  <span>accepted terms and conditions</span>
);

const AlertInstance = ({ alert }: { alert: AlertInstanceFragment }) => {
  let message: string;

  switch (alert.alertType) {
    case AlertType.AlertRule: {
      const alertRuleName = alert.alertRule?.alertRuleName;
      message = `alert${alertRuleName ? ` for rule ${alertRuleName}` : ''}`;
      break;
    }
    case AlertType.CheckupScheduleEvent:
      message = 'missed check-up alert';
      break;
    case AlertType.PatientCallbackRequest:
      message = 'callback request';
      break;
    case AlertType.PacsanaEvent:
      message = 'activity monitoring alert';
      break;
    default:
      message = 'alert';
      break;
  }

  return <span>{message}</span>;
};

const AlertRuleInstance = ({ alertRule }: { alertRule: AlertRuleInstanceFragment }) => (
  <span>
    alert rule <Entity>{alertRule.alertRuleName}</Entity>
  </span>
);

const CarePathwayInstance = ({ carePathway }: { carePathway: CarePathwayInstanceFragment }) => (
  <span>
    care pathway <Entity>{carePathway.carePathwayName}</Entity>
  </span>
);

const CheckupInstance = ({ checkup }: { checkup: CheckupInstanceFragment }) => (
  <span>
    <Entity>{checkup.checkupType}</Entity> check-up
  </span>
);

const CheckupConfigInstance = ({
  checkupConfig,
}: {
  checkupConfig: CheckupConfigInstanceFragment;
}) => (
  <span>
    check-up config <Entity>{checkupConfig.checkupConfigName}</Entity>
  </span>
);

const CheckupScheduleInstance = (_: { checkupSchedule: CheckupScheduleInstanceFragment }) => (
  <span>check-up schedule</span>
);

const CheckupScheduleEventInstance = ({
  checkupScheduleEvent,
}: {
  checkupScheduleEvent: CheckupScheduleEventInstanceFragment;
}) => (
  <span>{`schedule, expected at: ${feebrisDateFormatter.formatCheckupTimeLongWithoutWeekDay(
    checkupScheduleEvent.checkupExpectedAt,
  )}`}</span>
);

const CheckupTypeInstance = ({ checkupType }: { checkupType: CheckupTypeInstanceFragment }) => (
  <span>
    check-up type <Entity>{checkupType.checkupTypeName}</Entity>
  </span>
);

const ContinuousMonitoringSessionInstance = (_: {
  continuousMonitoringSession: ContinuousMonitoringSessionInstanceFragment;
}) => <span>continuous monitoring session</span>;

const ContinuousMonitoringInstance = (_: {
  continuousMonitoring: ContinuousMonitoringInstanceFragment;
}) => <span>continuous monitoring data</span>;

const PacsanaSessionInstance = (_: { pacsanaSession: PacsanaSessionInstanceFragment }) => (
  <span>activity monitoring session</span>
);

const PacsanaEventInstance = ({ pacsanaEvent }: { pacsanaEvent: PacsanaEventInstanceFragment }) => (
  <span>{pacsanaEvent.eventName} event</span>
);

const OrganizationInstance = ({ organization }: { organization: OrganizationInstanceFragment }) => (
  <span>
    organization <Entity>{organization.organizationName}</Entity>
  </span>
);

const EhrIntegrationInstance = ({
  integration,
}: {
  integration: EhrIntegrationInstanceFragment;
}) => (
  <span>
    <Entity>{formatIntegrationType(integration.integrationType)}</Entity>{' '}
    <Entity>
      {formatIntegrationTriggerType(integration.triggerType, integration.triggerConfig)}
    </Entity>
  </span>
);

const EhrIntegrationEventInstance = ({
  integrationEvent,
}: {
  integrationEvent: EhrIntegrationEventInstanceFragment;
}) => (
  <span>
    <EhrIntegrationInstance integration={integrationEvent.integration} />
    {integrationEvent.summaryPeriod ? (
      <>
        {' '}
        for period {format(new Date(integrationEvent.summaryPeriod.from), 'dd/MM/yyyy')} -{' '}
        {format(new Date(integrationEvent.summaryPeriod.to), 'dd/MM/yyyy')}
      </>
    ) : null}
  </span>
);

const EhrIntegrationIdentityInstance = ({
  identity,
}: {
  identity: EhrIntegrationIdentityInstanceFragment;
}) => (
  <span>
    <Entity>{formatIdentityType(identity.identityType)}</Entity>
  </span>
);

const NhsNumberInstance = (_: { nhsNumber: NhsNumberInstanceFragment }) => <span>NHS number</span>;

const PatientInstance = ({ patient }: { patient: PatientInstanceFragment }) => (
  <span>
    patient{' '}
    <Entity>
      {patient.patientFirstName} {patient.patientLastName}
    </Entity>
  </span>
);

const PatientNoteInstance = (_: { patientNote: PatientNoteInstanceFragment }) => <span>note</span>;

// NOTE: We still use the phrasing "personalised thresholds" because the ActionLogTab is limited
//       to action logs for a single patient
const EWSThresholdsInstance = (_: { ewsThresholds: EwsThresholdsInstanceFragment }) => (
  <span>personalised thresholds</span>
);

const IntegrationApiKeyInstance = (_: { integrationApiKey: IntegrationApiKeyInstanceFragment }) => (
  <span>API key</span>
);

const PictureInstance = ({ picture }: { picture: PictureInstanceFragment }) => (
  <span>picture for checkup. {picture.caption && `Caption: ${picture.caption}`}</span>
);

const ShareTokenInstance = ({ shareToken }: { shareToken: ShareTokenInstanceFragment }) => {
  const createdAt = new Date(shareToken.shareTokenCreatedAt);
  const expiresAt = new Date(shareToken.shareTokenExpiresAt);

  return (
    <span>
      at {format(createdAt, 'd/LL/yy p')},{' '}
      {expiresAt > new Date() ? `expires in ${formatDistance(expiresAt, createdAt)}` : 'expired'}
    </span>
  );
};

const UserInstance = ({ user }: { user: UserInstanceFragment }) => (
  <span>
    user{' '}
    <Entity>
      {/* NOTE: Normally the userActingOrganization argument is normally required but in this case
                we are describing when somebody created a specific Feebris user. It seems sensible
                to display the name of the user who was created. */}
      {formatUserName({
        email: user.email,
        firstName: user.userFirstName,
        lastName: user.userLastName,
        isSelfCare: false,
        isActiveTeamMember: true,
      })}
    </Entity>
  </span>
);

const WardInstance = ({ ward }: { ward: WardInstanceFragment }) => (
  <span>
    <Entity>{ward.wardName}</Entity>
  </span>
);

const Entity = ({ children }: { children: ReactNode }) => {
  const classes = useEntityStyles();
  return <Box className={classes.entity}>{children}</Box>;
};

interface EntityIcon {
  icon: typeof SvgIcon;
  color?: 'positive' | 'negative' | 'primary';
}

/**
 * This is an *ordered* map of entity types to icons
 *
 * The first entry in the array that matches the entity type will be used.
 * Be careful to order the entries from most specific to least specific.
 */
const entityIconMap: Record<string, (log: ActionLogItemFragment) => EntityIcon> = {
  CheckupScheduleEvent: () => ({ icon: WatchLaterIcon, color: 'negative' }),
  Alert: (log) => {
    if (log.message.includes('Set status to "closed"')) {
      return {
        icon: NotificationsOffIcon,
        color: 'positive',
      };
    }

    if (log.message.includes('Set status to "open"')) {
      return {
        icon: NotificationImportantIcon,
        color: 'negative',
      };
    }

    return {
      icon: NotificationsIcon,
    };
  },
  AlertRule: () => ({ icon: NotificationImportantIcon }),
  ContinuousMonitoringSession: () => ({ icon: WifiTetheringIcon }),
  PacsanaSession: () => ({ icon: DirectionsWalkIcon }),
  Ward: () => ({ icon: HotelIcon }),
  EWSThresholds: () => ({ icon: BarChartIcon }),
  IntegrationApiKey: () => ({ icon: VpnKeyIcon }),
  Organization: () => ({ icon: LocationCityIcon }),
  Patient: () => ({ icon: PersonIcon }),
  NhsNumber: () => ({ icon: PersonIcon }),
  OrganizationPatient: () => ({ icon: LocationCityIcon }),
  Checkup: () => ({ icon: AssignmentIcon }),
  PatientNote: () => ({ icon: NoteAddIcon }),
  Picture: () => ({ icon: PhotoIcon }),
  User: () => ({ icon: AccountBoxIcon }),
  UserOrganization: () => ({ icon: LocationCityIcon }),
  EhrIntegration: () => ({ icon: CloudUploadIcon }),
  EhrIntegrationEvent: () => ({ icon: CloudUploadIcon }),
  EhrIntegrationIdentity: () => ({ icon: VpnKeyIcon }),
};

const ActionIcon = ({ log }: { log: ActionLogItemFragment }) => {
  const classes = useActionIconStyles();

  const entityTypes = log.messageEntities?.map((entity) => entity.type) ?? [];

  const match = Object.entries(entityIconMap).find(([key]) => entityTypes.includes(key))?.[1];

  const { icon: Icon, color } = match?.(log) ?? {};

  const iconColorClass = Icon ? classes.genericAction : classes.unknownAction;

  return (
    <div
      className={clsx(
        classes.root,
        iconColorClass,
        color === 'positive' && classes.positiveAction,
        color === 'negative' && classes.negativeAction,
      )}>
      {Icon ? <Icon /> : '?'}
    </div>
  );
};

const useActionIconStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '39px',
    height: '39px',
    borderRadius: '50%',
    borderWidth: theme.typography.pxToRem(2),
    borderStyle: 'solid',
    fontSize: theme.typography.pxToRem(30),
  },
  unknownAction: {
    color: theme.palette.grey[400],
    borderColor: theme.palette.grey[400],
  },
  genericAction: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  negativeAction: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  positiveAction: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
  },
}));

const useEntityStyles = makeStyles((theme) => ({
  entity: {
    color: theme.palette.primary.main,
    display: 'inline-block',
  },
}));

const useStyles = makeStyles((theme) => ({
  timelineHead: {
    marginLeft: '42px',
    borderLeft: `3px solid ${theme.palette.primary.dark}`,
    height: '28px',
    '&::before': {
      content: '""',
      position: 'relative',
      display: 'inline-block',
      left: '-7px',
      top: '-7px',
      width: '11px',
      height: '11px',
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const useDateSectionStyles = makeStyles((theme) => ({
  dateSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '120px',
    marginRight: theme.spacing(3),
    flex: '0 0 auto',
  },
}));

const useActionLogItemStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius * 3,
    minHeight: '80px',
    position: 'relative',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(0, 3),
  },
  timelineLine: {
    marginLeft: '42px',
    borderLeft: `3px solid ${theme.palette.primary.dark}`,
    height: '24px',
  },
  iconContainer: {
    marginRight: theme.spacing(3),
    flex: '0 0 auto',
  },
  messageSection: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(18),
    flex: '1 1 auto',
  },
}));
