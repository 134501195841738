import { isEmergencyQuestion } from './Checkup';

export interface QuestionnaireQuestion {
  qnKey: keyof typeof questionKey;
  answer: number;
  subQuestions?: QuestionnaireQuestion[];
}

export interface QuestionnaireQuestionData {
  text: string;
  key: string;
}

export const generateQuestionnaireData = (questions: QuestionnaireQuestion[] | undefined) => {
  if (!questions) return [];

  const respondedQuestions: QuestionnaireQuestionData[] = [];

  // Check if the data coming through is the new format or old
  if (Array.isArray(questions)) {
    // Capture all yes from the questions and sub-questions.
    questions.forEach((mainQuestion) => {
      mainQuestion.answer === 1 &&
        respondedQuestions.push({
          text: questionKey[mainQuestion.qnKey] || mainQuestion.qnKey,
          key: mainQuestion.qnKey,
        });
      if (mainQuestion.subQuestions) {
        mainQuestion.subQuestions.forEach((subQuestion) => {
          subQuestion.answer === 1 &&
            respondedQuestions.push({
              text: questionKey[subQuestion.qnKey] || subQuestion.qnKey,
              key: subQuestion.qnKey,
            });
        });
      }
    });
  }

  return respondedQuestions;
};

interface QuestionnaireAnswer {
  text: string;
  isEmergencyQuestion: boolean;
}

export const generateSoftSignsData = (
  questions: QuestionnaireQuestion[],
): ReadonlyArray<QuestionnaireAnswer> => {
  const processed = generateQuestionnaireData(questions);

  return processed
    .map((question) => ({
      text: question.text,
      isEmergencyQuestion: isEmergencyQuestion(question.key),
    }))
    .sort((a, b) => (a.isEmergencyQuestion > b.isEmergencyQuestion ? -1 : 1));
};

export const questionKey = {
  // NOTE: the source from the left-side keys come from the mobile solution:
  // mobile/src/features/checkup/tiles/questionnaire/qnKeyConstants.ts

  // Child Checkup Questionnaire
  vomiting: 'Vomiting',
  diarrhoea: 'Diarrhoea',
  not_eating: 'Unable to eat',
  convulsions: 'Convulsions',
  breathing_issue: 'Difficulty breathing',
  fever: 'Fever',
  lethargic: 'Lethargic',
  unconscious: 'Unconscious',
  noisy_breath: 'Noisy breathing',
  indrawing_chest: 'Chest indrawing',

  // Disease History Questionnaire
  malnourished: 'Malnourished',
  breast_feed: 'Breastfed',

  measles: 'Measles',
  mumps: 'Mumps',
  c_pox: 'Chicken pox',
  tb: 'TB',
  rheumatic_fever: 'Rheumatic fever',
  seizures: 'Seizures',
  breath_problems: 'Breath problems',
  jaundice: 'Jaundice',

  outdoor_allergies: 'Outdoor allergies',
  food_allergies: 'Food allergies',
  med_allergies: 'Medicines allergies',
  other_allergies: 'Other allergies',
  no_allergies: 'No allergies',

  heart_problem_hospitalisation: 'Hospitalisation for heart problems',
  lung_problem_hospitalisation: 'Hospitalisation for lung problems',
  gastric_problem_hospitalisation: 'Hospitalisation for gastric problems',
  injury_hospitalisation: 'Hospitalisation for injuries',
  malnutrition_hospitalisation: 'Hospitalisation for malnutrition',
  cancer_hospitalisation: 'Hospitalisation for cancer',
  malaria_hospitalisation: 'Hospitalisation for malaria',
  other_problem_hospitalisation: 'Hospitalisation for other problem',

  measles_vaccine: 'Measles vaccine',
  mmr_vaccine: 'Mmr vaccine',
  c_pox_vaccine: 'Chicken pox vaccine',
  bcg_vaccine: 'BCG vaccine',
  pneumococcal_vaccine: 'Pneumococcal vaccine',
  rota_virus_vaccine: 'Rota virus vaccine',
  polio_vaccine: 'Polio vaccine',
  hep_b_vaccine: 'Hepatitis B vaccine',
  dpt_vaccine: 'DPT vaccine',

  mental_problems_parents: 'Parents with mental problems',
  malnourished_parents: 'Parents malnourished',
  hbp_parents: 'Parents with HBP',
  diabetes_parents: 'Parents with diabetes',
  lung_problem_parents: 'Parents with lung problems',

  mental_problems_siblings: 'Siblings with mental problems',
  malnourished_siblings: 'Siblings malnourished',
  hbp_siblings: 'Siblings with HBP',
  diabetes_siblings: 'Siblings with diabetes',
  lung_problem_siblings: 'Siblings with lung problems',

  // Elderly Checkup Questionnaire
  sudden_facial_weakness: 'Sudden facial weakness',
  sudden_arm_weakness: 'Sudden arm weakness',
  sudden_speech_problems: 'Sudden speech problems',
  sudden_sight_issue: 'Sudden sight issue',
  sudden_chest_pain: 'Sudden chest pain',
  fully_awake: 'Fully awake',
  reacts_on_talking: 'Reacts when you talk to them',
  knows_answers: 'Knows answers to questions',
  confused_as_usual: 'Confused as usual',
  withdraws_flexes: 'Withdraws limbs',
  cough: 'New/persistent cough',
  cough_dry: 'Dry cough',
  cough_wet: 'Wet cough',
  new_sputum: 'New phlegm',
  change_in_sputum: 'Change in phlegm',
  blood_in_sputum: 'Blood in sputum',
  change_sputum_color: 'Change in sputum color',
  change_sputum_amount: 'Change in sputum amount',
  change_sputum_consistency: 'Change in sputum consistency',
  difficulty_breathing: 'Increased difficulty breathing',
  fast_breathing: 'Faster breathing than usual',
  short_breathing_rest: 'Short of breathing when resting',
  short_breathing_moving: 'Short of breathing when moving',
  pain: 'Increased or new onset of pain',
  pain_upper_stomach: 'Upper abdominal pain',
  pain_lower_stomach: 'Lower abdominal pain',
  pain_musculoskeletal: 'Pain musculoskeletal',
  pain_urinating: 'Pain urinating',
  chest: 'Chest pain',
  head: 'Headache',
  stomach: 'Stomach pain',
  back: 'Back pain',
  limb: 'Limb pain',
  other: 'Other pain',
  weight_gain_loss: 'Weight gain/loss',
  unable_to_eat: 'Unable to eat',
  more_agitated: 'More agitated than usual',
  more_fatigued: 'More fatigued than usual',
  smelly_urine: 'Smelly urine',
  chills: 'Chills',
  urination_difficulty: 'Urination difficulty',
  dizzy: 'Dizzy',
  less_mobile: 'Less mobile',
  sleep_disrupted: 'Sleep disrupted',
  itchy_sore_throat: 'Itchy/sore throat',
  loss_of_smell_taste: 'Loss of smell/taste',
  inhaler_usage_change: 'New/changed inhaler usage',
  inhaler_usage_increased: 'Blue inhaler used more than normal',
  inhaler_spacer_increased: 'New/increased use of spacer',
  respiratory_fatigue: 'Increased respiratory fatigue',
  blood_in_stool: 'Blood in stool',
  dehydration: 'Dehydration',
  appetite_loss: 'Less appetite than usual',
  normal_fluid_intake: 'Normal fluid intake',
  reduced_fluid_intake: 'Reduced fluid intake',

  // added for NNUH_IVTherapy_CP1_20230703
  no_bowels_24: '24 hours since last bowel movement',
  iv_pain: 'IV line painful',
  iv_appearance: 'IV line red',
  medication_missed: 'Missed prescribed medication',
  medication_supply: 'Running out of medication in the next 7 days',
  more_unwell: 'More unwell than usual',

  // added for NCHC_Cardiac_CP1_20230918
  leg_swelling: 'Leg swelling',
  impaired_activities: 'Impaired daily activities',

  // added for NCHC_Respiratory_CP1_20230918
  clear_phlegm: 'Clear phlegm',
  yellow_phlegm: 'Yellow phlegm',
  green_phlegm: 'Green phlegm',
  brown_phlegm: 'Brown phlegm',

  // added for PHQ_9_Emotional_Wellbeing, BARTHEL_Activities_Daily_Living, Pain_Management on 2023-07-26
  disinterested: 'Little interest or pleasure in doing things',
  depressed: 'Feeling down, depressed or hopeless',
  sleep_disruption: 'Trouble falling asleep, staying asleep, or sleeping too much',
  energy: 'Feeling tired or having little energy',
  appetite: 'Poor appetite or overeating',
  bad_feeling: 'Feeling bad about themselves',
  concentration: 'Trouble concentrating',
  suicidal: 'Suicidal',

  // added for JPUH_Asthma_20231124, JPUH_Heart_Failure_20231124, JPUH_Frailty_20231124, JPUH_Surgical_20231124
  limb_swelling: 'Limb swelling',
  more_pillows_needed: 'More pillows while sleeping',
  difficulty_breathing_asleep: 'Difficulty breathing while asleep',
  fluid_restriction: 'Fluid restriction surpassed',
  almost_fallen: 'Almost fell',
  fallen_today: 'Fallen today',
  increased_wheeziness: 'Increased wheeziness',
  woken_by_asthma: 'Woken by asthma',
  sudden_bloating: 'Sudden bloating/tightness',
  confusion: 'More confused',
  stoma_changes: 'Stoma colour change',
  discharge_amount: 'Discharge amount',
  discharge_consistency: 'Discharge consistency',
  discharge_colour: 'Discharge colour',
  clear_discharge: 'Clear discharge',
  yellow_discharge: 'Yellow discharge',
  green_discharge: 'Green discharge',
  brown_discharge: 'Brown discharge',
  bloody_discharge: 'Bloody discharge',

  // added for JPUH_Pneumonia_20240102, JPUH_Osteomyelitis_20240102
  coating_of_tongue: 'Tongue coating',
  swelling_wound: 'Wound swelling',
  redness_wound: 'Wound redness',
  wound_warmth: 'Wound warm',

  // added for QEH_LRTI_6wk_11mo, QEH_LRTI_1y_4y, QEH_LRTI_5y_12y
  grunting: 'Grunting',
  head_bobbing: 'Head bobbing',
  breathing_pauses: 'Breathing pauses',
  half_feeds: 'Half of feeds',
  no_urination: 'No urination',
  chest_recessions: 'Chest recessions',
  uneven_chest_movement: 'Uneven chest movement',
  skin_changes: 'Skin changes',
  skin_pale: 'Pale skin',
  skin_grey: 'Grey skin',
  skin_blue: 'Blue skin',
  skin_mottled: 'Mottled skin',
  skin_clammy: 'Clammy skin',
  skin_other: 'Other skin change',

  // added for QEH_Paeds_Gastro_20240429
  new_rash: 'New rash',

  // added for QEH_Paeds_Generic_6mo_18y, QEH_Paeds_Wheeze_6mo_18y_LTRI_13y_18y
  body_swelling: 'Body swelling',
  bleeding: 'Bleeding',
  inhaler_other: 'Inhaler other',

  feeding: 'Difficulty feeding independently',
  bathing: 'Difficulty bathing independently',
  dressing: 'Difficulty getting dressed independently',
  bowel_control: 'Difficulty controlling bowel',
  occasional_bowel_issues: 'Bowel - Occasional accident',
  incontinent_bowel_issues: 'Bowel - Incontinent / uncontrolled',
  bladder_control: 'Difficulty controlling bladder',
  occasional_bladder_issues: 'Bladder - Occasional accident',
  incontinent_bladder_issues: 'Bladder - Incontinent / uncontrolled / catheterised',
  toilet_use: 'Difficulty using the toilet independently',
  transfers: 'Difficulty moving between bed and chair independently',
  transfers_minor: 'Transfers - Minor help required',
  transfers_major: 'Transfers - Major help required',
  transfers_unable: 'Transfers - Unable to complete',
  mobility_level: 'Difficulty moving independently on level surface',
  mobility_independent: 'Mobility level - Independent but may use any aid',
  mobility_minor: 'Mobility level - Walks with help of 1 person (physical or verbal)',
  mobility_major: 'Mobility level - Wheelchair independent, inc. corners',
  mobility_unable: 'Mobility level - Immobile',
  stairs: 'Difficulty using stairs',

  pain_increased_intensity: 'Pain intensity changed',
  no_pain: 'No pain',
  minimal_pain: 'Minimal pain',
  mild_pain: 'Mild pain',
  uncomfortable_pain: 'Uncomfortable pain',
  moderate_pain: 'Moderate pain',
  distracting_pain: 'Distracting pain',
  distressing_pain: 'Distressing pain',
  unmanageable_pain: 'Unmanageable pain',
  intense_pain: 'Intense pain',
  severe_pain: 'Severe pain',
  unable_to_move_pain: 'Unable to move due to pain',
  sharp_pain: 'Sharp pain',
  hot_pain: 'Hot pain',
  dull_pain: 'Dull pain',
  cold_pain: 'Cold pain',
} as const;
