import React, { ReactNode } from 'react';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  Link,
  TextField,
  Tooltip,
  Typography,
  Select,
  MenuItem,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import { useFormik } from 'formik';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useModal } from 'mui-modal-provider';

import { questionKey } from '@/helpers/questionnaire';
import {
  AdminAlertRulesItemFragmentInternal,
  AlertCheckupRulePreviewInternal,
  AlertContinuousMonitoringRulePreviewInternal,
  CarePathwayInternal,
  OrganizationInternal,
  useCreateAlertRuleMutationInternal,
  usePreviewCheckupAlertRuleMutationInternal,
  usePreviewContinuousMonitoringAlertRuleMutationInternal,
  useUpdateAlertRuleMutationInternal,
  AlertRuleTypesInternal,
} from '@/generated/graphql-internal';
import { gql } from '@apollo/client';

import { getMutationErrors } from '@/AuthorizedApolloClientProvider';

export interface AlertRuleFormDialogProps extends DialogProps {
  alertRule: AdminAlertRulesItemFragmentInternal | null;
  carePathway: Pick<CarePathwayInternal, 'id' | 'name'>;
  organizationId: string;
  organizationFeatures: OrganizationInternal['features'];
  onClose: () => void;
}

export default function AlertRuleFormDialog(props: AlertRuleFormDialogProps) {
  const { t } = useTranslation();

  const isNew = !props.alertRule?.id;

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="lg">
      <DialogTitle>{isNew ? t('Create Alert Rule') : t('Edit Alert Rule')}</DialogTitle>
      <AlertRuleForm
        initialValues={{
          name: props.alertRule?.name ?? '',
          type: props.alertRule?.type ?? AlertRuleTypesInternal.NewCheckupInternal,
          description: props.alertRule?.description ?? '',
          condition: props.alertRule?.condition ?? '',
        }}
        isNew={isNew}
        {...props}
      />
    </Dialog>
  );
}

export interface AlertRuleFormProps {
  alertRule: AdminAlertRulesItemFragmentInternal | null;
  carePathway: Pick<CarePathwayInternal, 'id' | 'name'>;
  organizationId: string;
  organizationFeatures: OrganizationInternal['features'];
  onClose: () => void;
  initialValues: {
    name: string;
    type: string;
    description: string;
    condition: string;
  };
  isNew: boolean;
}

export const PREVIEW_CHECKUP_ALERT_RULE = gql`
  mutation PreviewCheckupAlertRule($condition: String!, $organizationId: ID) {
    previewCheckupAlertRule(organizationId: $organizationId, condition: $condition) {
      condition
      percentTriggered
      numTested
      examples {
        id
        endedAt
        patient {
          id
        }
      }
    }
  }
`;

export const PREVIEW_CONTINUOUS_MONITORING_ALERT_RULE = gql`
  mutation PreviewContinuousMonitoringAlertRule($condition: String!, $organizationId: ID) {
    previewContinuousMonitoringAlertRule(organizationId: $organizationId, condition: $condition) {
      condition
      percentTriggered
      numTested
      examples {
        id
        bucketStartAt
        patient {
          id
        }
      }
    }
  }
`;

export const UPDATE_ALERT_RULE = gql`
  mutation UpdateAlertRule(
    $id: ID!
    $type: String!
    $name: String!
    $description: String!
    $condition: String!
  ) {
    updateAlertRule(
      id: $id
      type: $type
      name: $name
      description: $description
      condition: $condition
    ) {
      id
    }
  }
`;

export const CREATE_ALERT_RULE = gql`
  mutation CreateAlertRule(
    $carePathwayId: ID!
    $name: String!
    $type: String!
    $description: String!
    $condition: String!
  ) {
    createAlertRule(
      carePathwayId: $carePathwayId
      name: $name
      type: $type
      description: $description
      condition: $condition
    ) {
      id
    }
  }
`;

function getPreviewSeverity(
  result:
    | Pick<AlertCheckupRulePreviewInternal, 'percentTriggered'>
    | Pick<AlertContinuousMonitoringRulePreviewInternal, 'percentTriggered'>
    | undefined
    | null,
) {
  if (!result) {
    return undefined;
  }

  if (result.percentTriggered > 0.2) {
    return 'error';
  }

  return 'warning';
}

function AlertRuleForm(props: AlertRuleFormProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [previewCheckupAlertRule, { data: previewCheckupResultResponse }] =
    usePreviewCheckupAlertRuleMutationInternal({
      onError: (error) => {
        const { argErrors } = getMutationErrors(error);
        formik.setErrors(argErrors);
      },
    });

  const [
    previewContinuousMonitoringAlertRule,
    { data: previewContinuousMonitoringResultResponse },
  ] = usePreviewContinuousMonitoringAlertRuleMutationInternal({
    onError: (error) => {
      const { argErrors } = getMutationErrors(error);
      formik.setErrors(argErrors);
    },
  });

  const previewCheckupResult = previewCheckupResultResponse?.previewCheckupAlertRule;
  const previewContinuousMonitoringResult =
    previewContinuousMonitoringResultResponse?.previewContinuousMonitoringAlertRule;

  const previewResult = previewCheckupResult || previewContinuousMonitoringResult;
  const previewSeverity = getPreviewSeverity(previewResult);

  const [createAlertRule] = useCreateAlertRuleMutationInternal({
    onError: (error) => {
      const { argErrors } = getMutationErrors(error);
      formik.setErrors(argErrors);
      formik.setSubmitting(false);
    },
    onCompleted: () => {
      props.onClose();
    },
  });

  const [updateAlertRule] = useUpdateAlertRuleMutationInternal({
    onError: (error) => {
      const { argErrors } = getMutationErrors(error);
      formik.setErrors(argErrors);
      formik.setSubmitting(false);
    },
    onCompleted: () => {
      props.onClose();
    },
  });

  const formik = useFormik({
    initialValues: props.initialValues,
    onSubmit: async (values, { setSubmitting }) => {
      if (!previewResult) {
        return;
      }

      setSubmitting(true);

      if (props.isNew) {
        createAlertRule({
          variables: {
            ...values,
            carePathwayId: props.carePathway.id,
          },
        });
      } else {
        updateAlertRule({
          variables: {
            ...values,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            id: props.alertRule!.id,
          },
        });
      }
    },
  });

  const showPreview = (type: AlertRuleTypesInternal) => {
    type === AlertRuleTypesInternal.NewCheckupInternal
      ? previewCheckupAlertRule({
          variables: {
            ...formik.values,
            organizationId: props.organizationId,
          },
        })
      : previewContinuousMonitoringAlertRule({
          variables: {
            ...formik.values,
            organizationId: props.organizationId,
          },
        });
  };

  const muiFormikGetFieldProps = (name: string) => {
    return {
      name,
      value: _.get(formik.values, name),
      onChange: formik.handleChange,
      error: _.has(formik.errors, name),
      helperText: _.get(formik.errors, name),
    };
  };

  const saveDisabled =
    !previewResult ||
    (previewCheckupResult?.condition !== formik.values.condition &&
      previewContinuousMonitoringResult?.condition !== formik.values.condition);

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      <DialogContent>
        <DialogContentText>
          {props.isNew
            ? `Create a new Alert Rule for all patients with the ${props.carePathway.name} care pathway`
            : `Edit the existing Alert Rule which will affect all patients with the ${props.carePathway.name} care pathway`}
        </DialogContentText>
        <FormControl required error={_.has(formik.errors, 'type')} disabled={!props.isNew}>
          <InputLabel id="alert-rule-type">Type</InputLabel>
          <Select
            labelId="alert-rule-type"
            name="type"
            value={_.get(formik.values, 'type')}
            onChange={(e) => {
              formik.resetForm({
                values: { ...props.initialValues, type: e.target.value as string },
              });
            }}
            error={_.has(formik.errors, 'type')}>
            <MenuItem value={AlertRuleTypesInternal.NewCheckupInternal}>New Checkup</MenuItem>
            {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
            {props.organizationFeatures!.continuousMonitoring && (
              <MenuItem value={AlertRuleTypesInternal.ContinuousMonitoringInternal}>
                Continuous Monitoring
              </MenuItem>
            )}
          </Select>
          <FormHelperText>{_.get(formik.errors, 'role')}</FormHelperText>
        </FormControl>
        <br></br>
        <TextField
          label="Alert Rule Name"
          required
          className={classes.margin}
          style={{ width: '17em' }}
          // NOTE: This should match the database validation on the AlertRule model in the api
          inputProps={{ minLength: 1, maxLength: 16 }}
          {...muiFormikGetFieldProps('name')}
        />
        <TextField
          fullWidth
          multiline
          label="This alert will be triggered because…"
          required
          className={classes.margin}
          {...muiFormikGetFieldProps('description')}
        />
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <FormControl required fullWidth error={_.has(formik.errors, 'condition')}>
              <InputLabel htmlFor="condition">Rule condition</InputLabel>
              <Input
                id="condition"
                value={_.get(formik.values, 'condition')}
                onChange={formik.handleChange}
                aria-describedby="condition-text"
                multiline
                className={classes.inputCode}
              />
              <FormHelperText id="condition-text" component="div">
                <pre dangerouslySetInnerHTML={{ __html: _.get(formik.errors, 'condition', '') }} />
              </FormHelperText>
            </FormControl>
            {formik.isSubmitting ? <CircularProgress /> : null}
            {!formik.isSubmitting ? (
              <Button
                color="primary"
                variant="contained"
                onClick={() => showPreview(formik.values.type as AlertRuleTypesInternal)}>
                Check rule is OK
              </Button>
            ) : null}
            <br />
            <br />
            {previewCheckupResult &&
            _.get(formik.values, 'type') === AlertRuleTypesInternal.NewCheckupInternal ? (
              <Alert severity={previewSeverity}>
                <Typography variant="subtitle2" gutterBottom>
                  Preview:
                </Typography>
                {previewCheckupResult.numTested > 0 ? (
                  <>
                    <Typography gutterBottom>
                      This rule condition would trigger on{' '}
                      {new Intl.NumberFormat(undefined, { style: 'percent' }).format(
                        previewCheckupResult.percentTriggered,
                      )}{' '}
                      of the most recent {previewCheckupResult.numTested} checkups within this
                      organization.
                    </Typography>
                    <Typography gutterBottom>
                      <strong>Note:</strong> Whilst the rule is only applied to patients under the{' '}
                      {props.carePathway.name} care pathway, the preview is based on all checkups
                      within this organization.
                    </Typography>
                    {previewSeverity === 'error' && (
                      <Typography gutterBottom>
                        <strong>Warning:</strong> This rule condition triggers on a high percentage
                        of checkups. Please check the condition is correct.
                      </Typography>
                    )}
                  </>
                ) : (
                  <Typography gutterBottom>
                    This rule condition does not appear to have any syntax errors, but this
                    organization has no checkups so it is unclear how often this condition would
                    trigger.
                  </Typography>
                )}
                {previewCheckupResult.examples.length > 0 ? (
                  <>
                    <Typography className={classes.previewExamplesHeading}>
                      Example checkups which would trigger this rule:
                    </Typography>
                    <ul>
                      {previewCheckupResult.examples.map(
                        (checkup) =>
                          checkup.patient && (
                            <li key={checkup.id}>
                              <Link
                                to={`/patient/${checkup.patient.id}/checkup/${checkup.id}`}
                                component={RouterLink}
                                target="_blank">
                                Check-up on{' '}
                                {t('DATETIME_MEDIUM', { val: new Date(checkup.endedAt) })} for
                                patient ID {checkup.patient.id}
                              </Link>
                            </li>
                          ),
                      )}
                    </ul>
                  </>
                ) : null}
              </Alert>
            ) : null}
            {previewContinuousMonitoringResult &&
            _.get(formik.values, 'type') === AlertRuleTypesInternal.ContinuousMonitoringInternal ? (
              <Alert severity={previewSeverity}>
                <Typography variant="subtitle2" gutterBottom>
                  Preview:
                </Typography>
                {previewContinuousMonitoringResult.numTested > 0 ? (
                  <>
                    <Typography gutterBottom>
                      This rule condition would trigger on{' '}
                      {new Intl.NumberFormat(undefined, { style: 'percent' }).format(
                        previewContinuousMonitoringResult.percentTriggered,
                      )}{' '}
                      of the most recent {previewContinuousMonitoringResult.numTested} aggregates
                      within this organization.
                    </Typography>
                    <Typography gutterBottom>
                      <strong>Note:</strong> Whilst the rule is only applied to patients under the{' '}
                      {props.carePathway.name} care pathway, the preview is based on all aggregates
                      within this organization.
                    </Typography>
                    {previewSeverity === 'error' && (
                      <Typography gutterBottom>
                        <strong>Warning:</strong> This rule condition triggers on a high percentage
                        of aggregates. Please check the condition is correct.
                      </Typography>
                    )}
                  </>
                ) : (
                  <Typography gutterBottom>
                    This rule condition does not appear to have any syntax errors, but this
                    organization has no aggregates so it is unclear how often this condition would
                    trigger.
                  </Typography>
                )}
                {previewContinuousMonitoringResult.examples.length > 0 ? (
                  <>
                    <Typography className={classes.previewExamplesHeading}>
                      Example aggregates which would trigger this rule:
                    </Typography>
                    <ul>
                      {previewContinuousMonitoringResult.examples.map(
                        (aggregate) =>
                          aggregate.patient && (
                            <li key={aggregate.id}>
                              <Link
                                to={`/patient/${aggregate.patient.id}`}
                                component={RouterLink}
                                target="_blank">
                                Aggregate on{' '}
                                {t('DATETIME_MEDIUM', { val: new Date(aggregate.bucketStartAt) })}{' '}
                                for patient ID {aggregate.patient.id}
                              </Link>
                            </li>
                          ),
                      )}
                    </ul>
                  </>
                ) : null}
              </Alert>
            ) : null}
          </Grid>
          <Grid item md={6} xs={12}>
            <AlertRuleSyntaxHelp type={formik.values.type as AlertRuleTypesInternal} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()}>Cancel</Button>
        <Tooltip
          title={saveDisabled ? 'You must click the "Check rule is OK" button before saving' : ''}>
          {/* The div is necessary to wrap a disabled button in tooltip, see: https://stackoverflow.com/a/66713470 */}
          <div>
            <Button type="submit" color="primary" variant="contained" disabled={saveDisabled}>
              Save
            </Button>
          </div>
        </Tooltip>
      </DialogActions>
    </form>
  );
}

const useStyles = makeStyles((theme) => ({
  margin: {
    marginBottom: theme.spacing(2),
  },
  inputCode: {
    fontFamily: 'monospace',
  },
  previewResult: {
    backgroundColor: theme.palette.warning.light,
    padding: theme.spacing(2),
    color: theme.palette.warning.contrastText,
    border: `1px dashed ${theme.palette.warning.dark}`,
  },
  previewExamplesHeading: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    marginTop: theme.spacing(2),
  },
  scrollableBox: {
    // TODO: Make this scrollable on the y axis
  },
  variableNameHelp: {
    fontFamily: 'monospace',
    fontWeight: 400,
    fontSize: 10,
    paddingRight: theme.spacing(2),
  },
  syntaxType: {
    color: theme.palette.primary.main,
    fontSize: 10,
  },
}));

const VariableNameHelp = (props: { children: ReactNode }) => {
  const classes = useStyles();
  return (
    <Typography display="inline" className={classes.variableNameHelp}>
      {props.children}
    </Typography>
  );
};
const TypeHelp = (props: { children: ReactNode }) => {
  const classes = useStyles();
  return (
    <Typography display="inline" className={classes.syntaxType}>
      {props.children}
    </Typography>
  );
};

function AlertRuleSyntaxHelp(props: { type: AlertRuleTypesInternal }) {
  const classes = useStyles();
  return (
    <div className={classes.scrollableBox}>
      <Grid container spacing={2}>
        <Grid item md={7}>
          <Typography variant="subtitle2">Variables</Typography>
          {props.type === AlertRuleTypesInternal.NewCheckupInternal && <NewCheckupVariables />}
          {props.type === AlertRuleTypesInternal.ContinuousMonitoringInternal && (
            <ContinuousMonitoringVariables />
          )}
        </Grid>
        <Grid item md={5}>
          <Typography variant="subtitle2">Operators</Typography>
          <ul>
            <li>
              <VariableNameHelp>and</VariableNameHelp>
            </li>
            <li>
              <VariableNameHelp>or</VariableNameHelp>
            </li>
            <li>
              <VariableNameHelp>{'!='}</VariableNameHelp>
            </li>
            <li>
              <VariableNameHelp>{'=='}</VariableNameHelp>
            </li>
            <li>
              <VariableNameHelp>{'>='}</VariableNameHelp>
            </li>
            <li>
              <VariableNameHelp>{'>'}</VariableNameHelp>
            </li>
            <li>
              <VariableNameHelp>{'<='}</VariableNameHelp>
            </li>
            <li>
              <VariableNameHelp>{'<'}</VariableNameHelp>
            </li>
            <li>
              <VariableNameHelp>contains</VariableNameHelp>
              <TypeHelp>Must match all elements</TypeHelp>
            </li>
            <li>
              <VariableNameHelp>contains_any</VariableNameHelp>
              <TypeHelp>Must match at least one element</TypeHelp>
            </li>
          </ul>

          <Typography variant="subtitle2">Functions</Typography>
          <ul>
            <li>
              <VariableNameHelp>array</VariableNameHelp>
              <TypeHelp>{"eg: array('cough_dry', 'cough_wet')"}</TypeHelp>
            </li>
            <li>
              <VariableNameHelp>all</VariableNameHelp>
              <TypeHelp>{'eg: all(someArray, "item > 10")'}</TypeHelp>
            </li>
            <li>
              <VariableNameHelp>getitem</VariableNameHelp>
              <TypeHelp>{'eg: getitem(someArray, 0)'}</TypeHelp>
            </li>
          </ul>
        </Grid>
      </Grid>
    </div>
  );
}

export interface UseAlertRuleFormModalProps {
  onClose: () => void;
  carePathway: Pick<CarePathwayInternal, 'id' | 'name'>;
  organizationId: string;
  organizationFeatures: OrganizationInternal['features'];
}

export function useAlertRuleFormModal({
  onClose,
  carePathway,
  organizationId,
  organizationFeatures,
}: UseAlertRuleFormModalProps) {
  const { showModal } = useModal();

  return {
    showAlertRuleFormModal: ({
      alertRule,
    }: {
      alertRule: AdminAlertRulesItemFragmentInternal | null;
    }) => {
      const modal = showModal(
        AlertRuleFormDialog,
        {
          alertRule,
          carePathway,
          organizationId,
          organizationFeatures,
          onClose: () => {
            onClose();
            modal.hide();
          },
        },
        { destroyOnClose: true },
      );
    },
  };
}

function NewCheckupVariables() {
  return (
    <ul>
      <li>
        <VariableNameHelp>checkup.type</VariableNameHelp>
        <TypeHelp>String {"{'reactive', 'routine', 'custom'}"}</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>checkup.pulseRate.value</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>checkup.pulseRate.source</VariableNameHelp>
        <TypeHelp>String</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>checkup.pulseRate.isManual</VariableNameHelp>
        <TypeHelp>Boolean</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>checkup.bloodPressureData.pulse</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>checkup.bloodPressureData.systolic</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>checkup.bloodPressureData.diastolic</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>checkup.bloodPressureData.isManual</VariableNameHelp>
        <TypeHelp>Boolean</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>checkup.startedAt</VariableNameHelp>
        <TypeHelp>Date</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>checkup.endedAt</VariableNameHelp>
        <TypeHelp>Date</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>checkup.temperature</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>checkup.consciousness</VariableNameHelp>
        <TypeHelp>String {"{'A', 'V', 'U', 'P', 'C'}"}</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>checkup.respiratoryRate.value</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>checkup.respiratoryRate.source</VariableNameHelp>
        <TypeHelp>String</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>checkup.pulseOxiData.averageHr</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>checkup.pulseOxiData.averageSpO2</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>checkup.pulseOxiData.isManual</VariableNameHelp>
        <TypeHelp>Boolean</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>checkup.pulseOxiData.uneditedAverageSpO2</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>checkup.pulseOxiData.uneditedAverageHR</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>

      <li>
        <VariableNameHelp>checkup.glucose.reading</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>checkup.glucose.timeOfCapture</VariableNameHelp>
        <TypeHelp>Date</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>checkup.glucose.beforeAfterMeal</VariableNameHelp>
        <TypeHelp>
          String {"{'upon_waking', 'before_meals', 'at_least_90_mins_after_meals'}"})
        </TypeHelp>
      </li>
      <li>
        <VariableNameHelp>checkup.pictureWasTaken</VariableNameHelp>
        <TypeHelp>Boolean</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>checkup.stethWasUsed</VariableNameHelp>
        <TypeHelp>Boolean</TypeHelp>
      </li>

      <li>
        <VariableNameHelp>checkup.scores.BPScore</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>checkup.scores.consciousnessScore</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>checkup.scores.HRScore</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>checkup.scores.RRScore</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>checkup.scores.SpO2Score</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>checkup.scores.tempScore</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>checkup.scores.totalScore</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>checkup.scores.riskLevel</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>checkup.selectedAction</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      {/* NOTE: The questionnaire's TypeHelp is really long so we put it at the bottom for
                better readability */}
      <li>
        <VariableNameHelp>checkup.questionnaire</VariableNameHelp>
        <TypeHelp>array of String {"{'" + Object.keys(questionKey).join("', '") + "'}"}</TypeHelp>
      </li>
    </ul>
  );
}

function ContinuousMonitoringVariables() {
  return (
    <ul>
      <li>
        <VariableNameHelp>lastHour</VariableNameHelp>
        <TypeHelp>array of aggregates</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.spo2.min</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.spo2.max</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.spo2.mean</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.spo2.median</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.spo2.count</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.respiratoryRate.min</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.respiratoryRate.max</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.respiratoryRate.mean</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.respiratoryRate.median</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.respiratoryRate.count</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.heartRate.min</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.heartRate.max</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.heartRate.mean</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.heartRate.median</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.heartRate.count</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.pulseRate.min</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.pulseRate.max</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.pulseRate.mean</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.pulseRate.median</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.pulseRate.count</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.temperature.min</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.temperature.max</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.temperature.mean</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.temperature.median</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.temperature.count</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>

      <li>
        <VariableNameHelp>current.battery.ecg</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.battery.spo2</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.battery.temperature</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>

      <li>
        <VariableNameHelp>current.scores.BPScore</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.scores.HRScore</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.scores.RRScore</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.scores.SpO2Score</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.scores.tempScore</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.scores.totalScore</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
      <li>
        <VariableNameHelp>current.scores.riskLevel</VariableNameHelp>
        <TypeHelp>Number</TypeHelp>
      </li>
    </ul>
  );
}
