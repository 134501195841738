import React, { useEffect } from 'react';

import { toast } from 'sonner';
import { Stack, Typography } from '@mui/material';
import { gql } from '@apollo/client';

import { useAdmitPatientMutation } from '@/generated/graphql';

import { useAdmitPatientJourneyContext } from '../types';
import { formatNhsNumber } from '@/components/NhsNumber';

export const MUTATION_ADMIT_PATIENT = gql`
  mutation AdmitPatient($patientId: ID!, $wardId: ID!, $carePathwayId: ID!) {
    admitPatientToWard(patientId: $patientId, wardId: $wardId, carePathwayId: $carePathwayId) {
      patient {
        id
        wardAdmission {
          ...PatientAdmissionOverview
        }
      }
      admittedAt
    }
  }
`;

export function ConfirmAdmissionStep() {
  const {
    currentJourneyState: { patient, pendingWardAdmission: wardAdmission },
    setIsSubmitting,
    setActiveStep,
    handleStep,
  } = useAdmitPatientJourneyContext();

  const [admitPatient, { loading: isAdmitting }] = useAdmitPatientMutation({
    variables: {
      patientId: patient?.id ?? '',
      wardId: wardAdmission?.wardId ?? '',
      carePathwayId: wardAdmission?.carePathwayId ?? '',
    },
    onCompleted: () => {
      setActiveStep('success');
    },
    onError: () => toast.error('An error occured when admitting the patient to the ward'),
  });

  handleStep(async () => {
    await admitPatient();

    return false;
  });

  // Sync the mutation submitting state with the journey state
  useEffect(() => {
    setIsSubmitting(isAdmitting);
  }, [isAdmitting, setIsSubmitting]);

  return (
    <div>
      <Typography variant="body2" color="textSecondary">
        Please confirm the details below. Once the patient has been admitted, to change their ward
        or care pathway, they will need to be discharged and admitted again.
      </Typography>

      <Stack spacing={0.5} marginTop={2}>
        <Typography>
          <strong>Patient: </strong> {patient?.firstName} {patient?.lastName} (NHS Number:{' '}
          {patient?.nhsNumber ? formatNhsNumber(patient.nhsNumber) : 'Unknown'})
        </Typography>
        <Typography>
          <strong>Ward: </strong> {wardAdmission?.wardName}
        </Typography>
        <Typography>
          <strong>Care Pathway: </strong> {wardAdmission?.carePathwayName}
        </Typography>
        <Typography>
          <strong>Effective: </strong> Immediately
        </Typography>
      </Stack>
    </div>
  );
}
