import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import Header from './components/Header';
import Footer from './components/Footer';
import { ErrorBoundary, withErrorBoundary } from '@/components/ErrorBoundary';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface CareHomeDashboardLayoutProps {
  children: React.ReactNode;
}

function CareHomeDashboardLayout({ children }: CareHomeDashboardLayoutProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header />
      <main className={classes.content}>
        {/* Protect layout from dynamic route components crashing */}
        <ErrorBoundary>{children}</ErrorBoundary>
      </main>
      <Footer />
    </div>
  );
}

export default withErrorBoundary(CareHomeDashboardLayout);
