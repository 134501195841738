/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable quote-props */
import React, { Component, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Moment from 'moment';
import withStyles from '@mui/styles/withStyles';
import localeFormatting, { feebrisFormatter } from '@/helpers/LocaleFormatting';
import clsx from 'clsx';
import { Typography, Link, Tooltip, IconButton, Theme, Popover, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FirstPage, ChevronLeft, ChevronRight, LastPage } from '@mui/icons-material';
import { withTranslation } from 'react-i18next';
import { isEmergencyQuestion } from '@/helpers/Checkup';
import _ from 'lodash';
import Color from 'color';
import { PatientCheckupsTableItemFragment } from '@/generated/graphql';
import { QuestionnaireQuestion, generateQuestionnaireData } from '@/helpers/questionnaire';
import { SoftSignsChips } from '@/components/SoftSignsChips';

const useStyles = (theme: Theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  table: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontSize: '12px',
    borderCollapse: 'collapse',
    '& th': {
      fontSize: '12px',
      padding: theme.spacing(1),
      border: 'none',
      textAlign: 'right',
      paddingRight: theme.spacing(2),
      width: theme.spacing(15),
    },
    '& tr': {
      '&:nth-child(1)': {
        whiteSpace: 'nowrap',
        '& td': {
          '&:nth-child(n+2)': {
            '&:nth-last-child(n+1)': {
              border: 'none',
            },
          },
        },
        '& th': {
          '&:nth-child(1)': {
            minWidth: theme.spacing(2),
            maxWidth: theme.spacing(2),
            borderRight: '3px solid black',
          },
        },
      },
      '&:nth-child(2)': {
        borderTop: '3px solid black',
        '& th': {
          '&:nth-child(1)': {
            borderRight: '3px solid black',
          },
        },
      },
      '&:nth-child(10)': {
        borderTop: '3px solid black',
        '& th': {
          '&:nth-child(1)': {
            borderRight: '3px solid black',
          },
        },
      },
      '&:nth-child(11)': {
        borderTop: '3px solid black',
        '& th': {
          '&:nth-child(1)': {
            borderRight: '3px solid black',
          },
        },
      },
      '&:nth-child(12)': {
        '& th': {
          '&:nth-child(1)': {
            borderRight: '3px solid black',
          },
        },
      },
      '&:nth-child(n+2)': {
        '&:nth-child(-n+9)': {
          '& td': {
            border: '1px dotted',
          },
        },
      },
      '&:nth-child(n+3)': {
        '&:nth-child(-n+9)': {
          '& th': {
            '&:nth-child(1)': {
              borderRight: '3px solid black',
            },
          },
        },
      },
    },
    '& td': {
      minWidth: theme.spacing(8),
      maxWidth: theme.spacing(8),
      border: '1px solid #ddd',
      padding: theme.spacing(1),
    },
  },
  spo2: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    '& sub': {
      lineHeight: 2,
    },
  },
  infoIcon: {
    marginRight: theme.spacing(0.5),
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    '& p': {
      padding: 12, // Match sizing of icon buttons
    },
  },
});

interface CheckupSummaryTableProps {
  id: string;
  checkupData: PatientCheckupsTableItemFragment[];
  offset: number;
  limit: number;
  total: number;
  isFirstPageDisabled: boolean;
  isPreviousPageDisabled: boolean;
  isNextPageDisabled: boolean;
  isLastPageDisabled: boolean;
  firstPage: () => void;
  previousPage: () => void;
  nextPage: () => void;
  lastPage: () => void;
  hoverCheckupIndex: number | null;
  colorBands: any;
  t: any;
  classes: any;
}

interface CheckupSummaryTableState {
  bpSystolicScores: Array<number | null>;
  pulseScores: Array<number | null>;
  rrScores: Array<number | null>;
  spo2Scores: Array<number | null>;
  tempScores: Array<number | null>;
  consciousnessScores: Array<number | null>;
  totalScores: Array<number | string>;
  hoveredSoftSignsRef: SVGSVGElement | null;
}

class CheckupSummaryTable extends Component<CheckupSummaryTableProps, CheckupSummaryTableState> {
  constructor(props: CheckupSummaryTableProps) {
    super(props);
    this.state = {
      bpSystolicScores: [],
      pulseScores: [],
      rrScores: [],
      spo2Scores: [],
      tempScores: [],
      consciousnessScores: [],
      totalScores: [],
      hoveredSoftSignsRef: null,
    };
  }

  componentDidMount() {
    this.calculateScores();
  }

  componentDidUpdate(prevProps: CheckupSummaryTableProps) {
    if (
      this.props.checkupData !== prevProps.checkupData ||
      // Comparing the `t` function is how we detect that the language changed
      // see: https://github.com/i18next/react-i18next/issues/831#issuecomment-484365753
      this.props.t !== prevProps.t
    ) {
      this.calculateScores();
    }
  }

  calculateScores() {
    const bpSystolicScores: Array<number | null> = [];
    const pulseScores: Array<number | null> = [];
    const rrScores: Array<number | null> = [];
    const spo2Scores: Array<number | null> = [];
    const tempScores: Array<number | null> = [];
    const consciousnessScores: Array<number | null> = [];
    const totalScores: Array<number | string> = [];

    for (const checkup of this.props.checkupData) {
      const ewsScores = checkup?.ewsScores;

      const bpScore = _.get(ewsScores, 'BPScore', null);
      const hrScore = _.get(ewsScores, 'HRScore', null);
      const rrScore = _.get(ewsScores, 'RRScore', null);
      const spo2Score = _.get(ewsScores, 'SpO2Score', null);
      const tempScore = _.get(ewsScores, 'tempScore', null);
      const consciousnessScore = _.get(ewsScores, 'consciousnessScore', null);

      bpSystolicScores.push(bpScore);
      pulseScores.push(hrScore);
      rrScores.push(rrScore);
      spo2Scores.push(spo2Score);
      tempScores.push(tempScore);
      consciousnessScores.push(consciousnessScore);

      const totalScore = ewsScores?.totalScore ?? 'N/A';

      totalScores.push(totalScore);
    }

    this.setState({
      bpSystolicScores,
      pulseScores,
      rrScores,
      spo2Scores,
      tempScores,
      consciousnessScores,
      totalScores,
    });
  }

  assignColorBand(score: number) {
    return this.props.colorBands[score];
  }

  colorBandCellStyle(index: number, score: number | undefined | null = undefined) {
    const shouldHighlight = index === this.props.hoverCheckupIndex;
    let backgroundColor = _.isNumber(score) ? this.assignColorBand(score) : 'rgb(255,255,255)';
    if (shouldHighlight) {
      // NOTE: Material UI table should be darkened by roughly 5%, according to
      //       https://material.io/components/data-tables#behavior; however, that feels too
      //       subtle on our tables so using 10% instead.
      // TODO: Should really use constants from material theme for this.
      backgroundColor = Color(backgroundColor).darken(0.1).rgb().string();
    }
    return { backgroundColor };
  }

  renderDates() {
    return this.props.checkupData.map((checkup, index) => {
      if (!checkup) {
        return <td key={index}></td>;
      }

      const fullFormattedDate = localeFormatting.formatCheckupTimeLongWithoutWeekDay(
        checkup.endedAt,
      );
      const date = Moment(checkup.endedAt).format('MMM DD');
      const time = Moment(checkup.endedAt).format('h:mm a');
      const checkupId = checkup.id;
      return (
        <td key={checkupId}>
          <Tooltip title={fullFormattedDate}>
            <Link to={`/patient/${this.props.id}/checkup/${checkupId}`} component={RouterLink}>
              {date}
              <br />
              {time}
            </Link>
          </Tooltip>
        </td>
      );
    });
  }

  renderRespiratoryRate() {
    return this.props.checkupData.map((checkup, index) => {
      if (!checkup) {
        return <td key={index}></td>;
      }
      const rrRate = _.get(checkup, 'respiratoryRate.value', '');
      const rrScore = this.state.rrScores[index];
      const checkupId = checkup.id;
      return (
        <td key={checkupId} style={this.colorBandCellStyle(index, rrScore)}>
          {rrRate}
        </td>
      );
    });
  }

  renderSpo2() {
    return this.props.checkupData.map((checkup, index) => {
      if (!checkup) {
        return <td key={index}></td>;
      }
      const spo2 = _.get(checkup, 'pulseOxiData.averageSpO2', '');
      const spo2Score = this.state.spo2Scores[index];
      const checkupId = checkup.id;
      return (
        <td key={checkupId} style={this.colorBandCellStyle(index, spo2Score)}>
          {spo2}
        </td>
      );
    });
  }

  renderBpSystolic() {
    return this.props.checkupData.map((checkup, index) => {
      if (!checkup) {
        return <td key={index}></td>;
      }
      const bpSystolic = _.get(checkup, 'bloodPressureData.systolic', '');
      const bpSystolicScore = this.state.bpSystolicScores[index];
      const checkupId = checkup.id;
      return (
        <td key={checkupId} style={this.colorBandCellStyle(index, bpSystolicScore)}>
          {bpSystolic}
        </td>
      );
    });
  }

  renderPulse() {
    return this.props.checkupData.map((checkup, index) => {
      if (!checkup) {
        return <td key={index}></td>;
      }
      const pulse = _.get(checkup, 'pulseRate.value', '');
      const pulseScore = this.state.pulseScores[index];
      const checkupId = checkup.id;
      return (
        <td key={checkupId} style={this.colorBandCellStyle(index, pulseScore)}>
          {pulse}
        </td>
      );
    });
  }

  renderTemperature() {
    return this.props.checkupData.map((checkup, index) => {
      if (!checkup) {
        return <td key={index}></td>;
      }
      const temperature = _.get(checkup, 'temperature', '');
      const tempScore = this.state.tempScores[index];
      const checkupId = checkup.id;
      return (
        <td key={checkupId} style={this.colorBandCellStyle(index, tempScore)}>
          {feebrisFormatter.formatTemperatureWithUnits(temperature)}
        </td>
      );
    });
  }

  renderConsciousness() {
    return this.props.checkupData.map((checkup, index) => {
      if (!checkup) {
        return <td key={index}></td>;
      }
      const consciousness = _.get(checkup, 'consciousness', '');
      const consciousnessScore = this.state.consciousnessScores[index];
      const checkupId = checkup.id;
      return (
        <td key={checkupId} style={this.colorBandCellStyle(index, consciousnessScore)}>
          {consciousness}
        </td>
      );
    });
  }

  renderBPDiastolic() {
    return this.props.checkupData.map((checkup, index) => {
      if (!checkup) {
        return <td key={index}></td>;
      }
      const bpDiastolic = _.get(checkup, 'bloodPressureData.diastolic', '');
      const checkupId = checkup.id;
      return (
        <td key={checkupId} style={this.colorBandCellStyle(index)}>
          {bpDiastolic}
        </td>
      );
    });
  }

  renderTotalScore() {
    return this.props.checkupData.map((checkup, index) => {
      if (!checkup) {
        return <td key={index}></td>;
      }
      const totalScore = this.state.totalScores[index];
      const checkupId = checkup.id;
      return (
        <td key={checkupId} style={this.colorBandCellStyle(index)}>
          {totalScore}
        </td>
      );
    });
  }

  renderSelectedAction() {
    return this.props.checkupData.map((checkup, index) => {
      if (!checkup) {
        return <td key={index}></td>;
      }

      const selectedAction = _.get(checkup, 'selectedAction', '');
      const selectedActionFormatted = this.props.t([
        `CHECKUP_ACTIONS_SHORT.${selectedAction}`,
        'N/A',
      ]);
      const checkupId = checkup.id;
      return (
        <td key={checkupId} style={this.colorBandCellStyle(index)}>
          {selectedActionFormatted}
        </td>
      );
    });
  }

  renderSoftSigns() {
    return this.props.checkupData.map((checkup, index) => {
      if (!checkup) {
        return <td key={index}></td>;
      }

      const checkupId = checkup.id;
      return (
        <td key={checkupId}>
          <SoftSignsDisplay questionnaire={checkup.questionnaire} />
        </td>
      );
    });
  }

  renderWeight() {
    return this.props.checkupData.map((checkup, index) => {
      if (!checkup) {
        return <td key={index}></td>;
      }
      return (
        <td key={checkup.id} style={this.colorBandCellStyle(index)}>
          {checkup.weight != null ? feebrisFormatter.formatWeight(checkup.weight) : null}
        </td>
      );
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.main}>
        <Typography variant="h6">Patient Check-ups</Typography>
        <table className={clsx(classes.table, 'e2e__checkupsummarytable')}>
          <tbody>
            <tr>
              <th>Date</th>
              {this.renderDates()}
            </tr>
            <tr>
              <th>Breathing Rate</th>
              {this.renderRespiratoryRate()}
            </tr>
            <tr>
              <th>
                <div className={classes.spo2}>
                  <div>SpO</div>
                  <sub>2</sub>
                </div>
              </th>
              {this.renderSpo2()}
            </tr>
            <tr>
              <th>BP Syst</th>
              {this.renderBpSystolic()}
            </tr>
            <tr>
              <th>BP Diast</th>
              {this.renderBPDiastolic()}
            </tr>
            <tr>
              <th>Pulse Rate</th>
              {this.renderPulse()}
            </tr>
            <tr>
              <th>Temperature</th>
              {this.renderTemperature()}
            </tr>
            <tr>
              <th>Consciousness</th>
              {this.renderConsciousness()}
            </tr>
            <tr>
              <th>Weight ({feebrisFormatter.weightUnits()})</th>
              {this.renderWeight()}
            </tr>
            <tr>
              <th>Soft Signs</th>
              {this.renderSoftSigns()}
            </tr>
            <tr>
              <th>Total Score (EWS)</th>
              {this.renderTotalScore()}
            </tr>
            <tr>
              <th>Selected Action</th>
              {this.renderSelectedAction()}
            </tr>
          </tbody>
        </table>
        <div className={classes.pagination}>
          <Typography display="inline">
            {this.props.offset + 1} &ndash; {this.props.offset + this.props.limit} of{' '}
            {this.props.total}
          </Typography>
          <IconButton
            aria-label="first page"
            onClick={this.props.firstPage}
            disabled={this.props.isFirstPageDisabled}
            size="large">
            <FirstPage />
          </IconButton>
          <IconButton
            aria-label="previous page"
            onClick={this.props.previousPage}
            disabled={this.props.isPreviousPageDisabled}
            size="large">
            <ChevronLeft />
          </IconButton>
          <IconButton
            aria-label="next page"
            onClick={this.props.nextPage}
            disabled={this.props.isNextPageDisabled}
            size="large">
            <ChevronRight />
          </IconButton>
          <IconButton
            aria-label="last page"
            onClick={this.props.lastPage}
            disabled={this.props.isLastPageDisabled}
            size="large">
            <LastPage />
          </IconButton>
        </div>
      </div>
    );
  }
}

interface SoftSignsDisplayProps {
  questionnaire: PatientCheckupsTableItemFragment['questionnaire'];
}

function SoftSignsDisplay({ questionnaire }: SoftSignsDisplayProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const classes = useSoftSignsStyles();

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const questionnaireData = generateQuestionnaireData(
    (questionnaire as QuestionnaireQuestion[]) ?? [],
  );
  if (questionnaireData.length === 0) {
    return null;
  }

  const hasEmergencyResponse = questionnaireData.some((a) => isEmergencyQuestion(a.key));

  return (
    <>
      <div
        aria-haspopup="true"
        aria-owns="soft-signs-popover"
        data-testid="soft-signs-indicator"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        className={clsx(
          classes.indicatorContainer,
          hasEmergencyResponse && classes.emergencyIndicatorContainer,
        )}>
        {questionnaireData.length}
      </div>
      <Popover
        id="soft-signs-popover"
        data-testid="soft-signs-popover"
        open={Boolean(anchorEl)}
        disableRestoreFocus
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        style={{ pointerEvents: 'none', marginTop: '8px' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <Box padding={2} maxWidth={440}>
          <SoftSignsChips questionnaire={(questionnaire as QuestionnaireQuestion[]) ?? []} />
        </Box>
      </Popover>
    </>
  );
}

const useSoftSignsStyles = makeStyles((theme) => ({
  indicatorContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
    fontWeight: 800,
    cursor: 'pointer',
    marginTop: theme.spacing(0.25),
    // Let's draw a diamond shape with CSS
    '&::before': {
      content: '""',
      position: 'absolute',
      zIndex: -1,
      left: '50%',
      top: '48%',
      transform: 'translate(-50%, -50%) rotate(45deg)',
      borderRadius: '2px',
      width: '20px',
      height: '20px',
      backgroundColor: theme.palette.grey[800],
    },
  },
  emergencyIndicatorContainer: {
    '&::before': {
      backgroundColor: theme.palette.error.main,
    },
  },
}));

export default withTranslation()(withStyles(useStyles as any)(CheckupSummaryTable));
