import React from 'react';
import { useTranslation } from 'react-i18next';

import '@/styles/components/blocks/block-questionnaire.scss';

import { SoftSignsChips } from '@/components/SoftSignsChips';
import { QuestionnaireQuestion } from '@/helpers/questionnaire';

interface BlockQuestionnaireProps {
  questionnaireData: QuestionnaireQuestion[] | null | undefined;
}

export default function BlockQuestionnaire({ questionnaireData }: BlockQuestionnaireProps) {
  const { t } = useTranslation();

  const hasQuestionnaireData = questionnaireData && questionnaireData.length > 0;

  return (
    <div className="block block--questionnaire">
      <div className="block__title">{t('Soft Signs')}</div>
      <div className="block__inner">
        {!hasQuestionnaireData ? (
          <div className="block__cols">
            <div>
              <p className="value">-</p>
            </div>
          </div>
        ) : (
          <SoftSignsChips showHeader={false} questionnaire={questionnaireData} />
        )}
      </div>
    </div>
  );
}
