import React, { useMemo, useState } from 'react';

import { gql } from '@apollo/client';
import { Column } from '@material-table/core';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { IntegrationDisplayFragment } from '@/generated/graphql';

import MaterialTableWithIcons from '@/components/MaterialTableWithIcons';
import {
  formatIntegrationTriggerType,
  formatIntegrationType,
} from '@/components/EhrIntegrations/format';
import { useRegisterAsApprover } from './useRegisterAsApprover';
import { isDefined } from '@/helpers/isDefined';
import { useRemoveAutoApprover } from './useRemoveAutoApprover';
import { useMePermissions } from '@/hooks/useAuth';

export const PATHWAY_INTEGRATIONS_FRAGMENT = gql`
  fragment IntegrationDisplay on EhrIntegration {
    id
    integrationType
    triggerType
    createdAt
    updatedAt
    authorizedAutomaticApprover {
      id
      email
    }
    triggerConfig {
      ...FormattableIntegrationTriggerConfig
    }
  }
`;

interface PathwayIntegrationsProps {
  pathwayId: string;
  loading: boolean;
  integrations: IntegrationDisplayFragment[];
  isQuicksilvaIdentitySetup: boolean;
  onUpdated: () => void;
}

export function PathwayIntegrations({
  loading,
  integrations,
  isQuicksilvaIdentitySetup,
  onUpdated,
}: PathwayIntegrationsProps) {
  console.log('integrations', integrations);

  const columns = useMemo(
    () =>
      [
        { field: 'id', hidden: true },
        {
          field: 'integrationType',
          title: 'Type',
          render: (data) => formatIntegrationType(data.integrationType),
        },
        {
          field: 'triggerType',
          title: 'Trigger',
          render: (data) => formatIntegrationTriggerType(data.triggerType, data.triggerConfig),
        },
        { field: 'createdAt', title: 'Created', type: 'datetime' },
        { field: 'updatedAt', title: 'Updated', type: 'datetime' },
        {
          field: 'authorizedAutomaticApprover',
          title: 'Automatic Approver',
          render: (data) => data.authorizedAutomaticApprover?.email,
        },
        {
          field: 'id',
          width: '48px',
          align: 'right',
          render: (data) => (
            <IntegrationMenu
              integration={data}
              isQuicksilvaIdentitySetup={isQuicksilvaIdentitySetup}
              onUpdated={onUpdated}
            />
          ),
        },
      ] as Column<IntegrationDisplayFragment>[],
    [isQuicksilvaIdentitySetup, onUpdated],
  );

  return (
    <div>
      <MaterialTableWithIcons
        title={null}
        columns={columns}
        data={integrations}
        isLoading={loading}
      />
    </div>
  );
}

interface IntegrationMenuProps {
  integration: IntegrationDisplayFragment;
  isQuicksilvaIdentitySetup: boolean;
  onUpdated: () => void;
}

function IntegrationMenu({
  integration,
  isQuicksilvaIdentitySetup,
  onUpdated,
}: IntegrationMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const registerAsApprover = useRegisterAsApprover({
    integrationId: integration.id,
    onRegistered: () => {
      setAnchorEl(null);
      onUpdated();
    },
  });

  const removeAutoApprover = useRemoveAutoApprover({
    integrationId: integration.id,
    onRemoved: () => {
      setAnchorEl(null);
      onUpdated();
    },
  });

  const permissions = useMePermissions();

  const menuItems = useMemo(
    () =>
      [
        {
          requiredPermission: 'edit_ehr_integrations',
          disabled: !isQuicksilvaIdentitySetup,
          label: 'Register as Automatic Approver',
          onClick: registerAsApprover,
        },
        {
          requiredPermission: 'edit_ehr_integrations',
          disabled: !isDefined(integration.authorizedAutomaticApprover),
          label: 'Remove Automatic Approver',
          onClick: removeAutoApprover,
        },
      ].filter((item) => !item.requiredPermission || permissions[item.requiredPermission] === true),
    [
      isQuicksilvaIdentitySetup,
      integration.authorizedAutomaticApprover,
      permissions,
      registerAsApprover,
      removeAutoApprover,
    ],
  );

  if (menuItems.length === 0) {
    return null;
  }

  return (
    <>
      <IconButton aria-label="Open Menu" onClick={(e) => setAnchorEl(e.currentTarget)} size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}>
        {menuItems.map((item) => (
          <MenuItem
            key={item.label}
            disabled={item.disabled}
            onClick={() => {
              item.onClick();
              setAnchorEl(null);
            }}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
