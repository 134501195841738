import React, { useState } from 'react';
import {
  Box,
  BoxProps,
  Button,
  CircularProgress,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { gql } from '@apollo/client';
import {
  useAddQuicksilvaIdentityMutation,
  useRemoveQuicksilvaIdentityMutation,
  useIsQuicksilvaIdentitySetupQuery,
} from '@/generated/graphql';
import { isDefined } from '@/helpers/isDefined';
import Loading from '@/components/Loading';
import { toast } from 'sonner';
import { useConfirm } from 'material-ui-confirm';

export const ADD_QUICKSILVA_IDENTITY = gql`
  mutation addQuicksilvaIdentity($accessCode: String!) {
    addQuicksilvaIdentity(accessCode: $accessCode)
  }
`;

export const REMOVE_QUICKSILVA_IDENTITY = gql`
  mutation removeQuicksilvaIdentity {
    removeQuicksilvaIdentity
  }
`;

export const GET_QUICKSILVA_IDENTITY_STATUS = gql`
  query isQuicksilvaIdentitySetup {
    me {
      isQuicksilvaIdentitySetup
    }
  }
`;

export function QuicksilvaIntegrationCard(props: BoxProps) {
  const classes = useStyles();
  const [accessCode, setAccessCode] = useState<string>();
  const {
    data,
    refetch: refetchIsQuicksilvaIdentitySetup,
    loading: getIsQuicksilvaIdentitySetupLoading,
  } = useIsQuicksilvaIdentitySetupQuery();

  const isQuicksilvaIdentitySetup = data?.me?.isQuicksilvaIdentitySetup === true;

  const confirm = useConfirm();

  const [
    addQuicksilvaIdentity,
    { loading: addingQuicksilvaIdentity, error: addQuicksilvaIdentityError },
  ] = useAddQuicksilvaIdentityMutation({
    onCompleted: async () => {
      await refetchIsQuicksilvaIdentitySetup();
      toast.success('Successfully linked Quicksilva identity');
    },
    onError: () => toast.error('There was a problem linking identity'),
  });

  const [removeQuicksilvaIdentity, { loading: removingQuicksilvaIdentity }] =
    useRemoveQuicksilvaIdentityMutation({
      onCompleted: async () => {
        await refetchIsQuicksilvaIdentitySetup();
        toast.success('Successfully unlinked Quicksilva identity');
      },
      onError: () => toast.error('There was a problem unlinking identity'),
    });

  const handleAddIdentitySubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    // Shouldn't happen; just satisfying TypeScript
    if (!isDefined(accessCode)) {
      return;
    }

    await addQuicksilvaIdentity({ variables: { accessCode } });
  };

  const handleRemoveIdentitySubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    try {
      await confirm({
        description: 'Are you sure you want to unlink your Quicksilva identity?',
        confirmationText: 'Unlink',
        confirmationButtonProps: {
          variant: 'contained',
          color: 'error',
        },
      });
      await removeQuicksilvaIdentity();
    } catch {
      // catch the weird error when the 'cancel' button is clicked
      // ¯\_(ツ)_/¯
    }
  };

  const linkQuicksilvaIdentityFormId = 'linkQuicksilvaIdentityForm';
  const unlinkQuicksilvaIdentityFormId = 'unlinkQuicksilvaIdentityForm';

  return (
    <Loading showLoading={getIsQuicksilvaIdentitySetupLoading}>
      <Box {...props} maxWidth="1024px" alignSelf="left">
        <Paper>
          <Box padding={3}>
            <Typography variant="h6" className={classes.heading}>
              Manage Quicksilva Identity
            </Typography>

            {isQuicksilvaIdentitySetup === false && (
              <form id={linkQuicksilvaIdentityFormId}>
                <Box marginBottom={1}>
                  <Typography>
                    Link your Feebris account to Quicksilva to enable pre-approval of messages.
                  </Typography>
                </Box>
                <Box marginBottom={3}>
                  <TextField
                    label="Access token"
                    name="accessToken"
                    autoComplete="off"
                    onChange={(e) => {
                      setAccessCode(e.target.value);
                    }}
                    error={isDefined(addQuicksilvaIdentityError)}
                    helperText={
                      isDefined(addQuicksilvaIdentityError)
                        ? 'Please check your access code and try again'
                        : undefined
                    }
                  />
                </Box>
                <Box display="flex" alignItems="center" marginTop={2}>
                  <Button
                    type="submit"
                    form={linkQuicksilvaIdentityFormId}
                    onClick={async (e) => await handleAddIdentitySubmit(e)}
                    disabled={!isDefined(accessCode)}
                    variant="contained"
                    color="primary">
                    Link identity
                  </Button>
                  {addingQuicksilvaIdentity && <CircularProgress size={24} />}
                </Box>
              </form>
            )}

            {isQuicksilvaIdentitySetup && (
              <form id={unlinkQuicksilvaIdentityFormId}>
                <Box marginBottom={1}>
                  <Typography>
                    Your account has been succesfully linked to Quicksilva. If you need to link to a
                    different Quicksilva identity please remove your Quicksilva identity first.
                  </Typography>
                </Box>
                <Box marginBottom={1}>
                  <Typography>
                    You can unlink your Quicksilva identity from Feebris at any time.
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" marginTop={2}>
                  <Button
                    type="submit"
                    form={unlinkQuicksilvaIdentityFormId}
                    className={classes.removeButton}
                    onClick={async (e) => await handleRemoveIdentitySubmit(e)}
                    variant="contained">
                    Unlink identity
                  </Button>
                  {removingQuicksilvaIdentity && <CircularProgress size={24} />}
                </Box>
              </form>
            )}
          </Box>
        </Paper>
      </Box>
    </Loading>
  );
}

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(2),
  },
  removeButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));
