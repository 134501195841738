import React, { useEffect, useState, useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { Button, Chip, Grid, Tooltip } from '@mui/material';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { Action, Column, MTableAction } from '@material-table/core';
import _ from 'lodash';
import MaterialTableWithIcons, {
  MaterialTableWithIconsHeaderWithId,
} from '@/components/MaterialTableWithIcons';
import IconButtonNoPropagation from '@/components/IconButtonNoPropagation';
import api from '@/controllers/Api';
import PatientFormModal from './components/PatientFormModal';
import AddPracticeDialog from './components/AddPracticeDialog';
import Loading from '@/components/Loading';
import NhsNumber from '@/components/NhsNumber';
import ChipWithTooltip from '@/components/ChipWithTooltip';
import auth from '@/controllers/Auth';
import MoreActionsMenu from './MoreActionsMenu';
import AssignPracticeAlert from './AssignPracticeAlert';
import SetPasswordModal from './components/SetPasswordModal';
import { AssignmentType, PatientAssignmentToolbar } from './components/PatientAssignmentToolbar';
import { DeletePatientModal } from './components/DeletePatientModal';
import { ManagePatientItemFragment } from '@/generated/graphql';
import { GraphQLOrganization } from '@/controllers/types';
import { toast } from 'sonner';
import { usePatientAdmitJourney } from '@/components/Journeys/AdmitPatientJourney';

interface PatientListProps {
  patients: ManagePatientItemFragment[];
  isLoading: boolean;
  reloadPatients: () => void;
  openPatientBulkUploadModal: () => void;
  onRowClick: (rowData: ManagePatientItemFragment) => void;
}

type WithTableData<T> = T & { tableData: { checked: boolean } };

export default function PatientList({
  patients,
  isLoading,
  reloadPatients,
  openPatientBulkUploadModal,
  onRowClick,
}: PatientListProps) {
  const wardsEnabled = auth.me('actingOrganization.features.wards', false);

  const classes = useStyles();
  const { t } = useTranslation();
  const [allPractices, setAllPractices] = useState<ManagePatientItemFragment['practices']>([]);
  const [assignedPractices, setAssignedPractices] = useState<
    Record<string, ManagePatientItemFragment['practices']>
  >({});
  const [activePatient, setActivePatient] = useState<WithTableData<ManagePatientItemFragment>>();
  const [patientFormModalOpen, setPatientFormModalOpen] = useState(false);
  const setFlashMessage = (message: string) => toast.success(message);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [assignPracticeAlertOpen, setAssignPracticeAlertOpen] = useState(false);
  const [addPracticeOpen, setAddPracticeOpen] = useState(false);
  const [setPasswordModalOpen, setSetPasswordModalOpen] = useState(false);

  const [selectedPatientIds, setSelectedPatientIds] = useState<string[]>([]);
  const [assignmentType, setAssignmentType] = useState<AssignmentType | null>(null);

  /**
   * The @material-table/core library handles selection state by mutating the data passed to it.
   * When handling the onSelection change event to detect that we should enter a bulk assignment
   * it stops detecting changes properly. Eventually we should replace this library for something
   * more suiting of Reacts preference towards immutable data. For now we've seized the means of
   * selection by having a separate state for the selected patient ids and then mapping that to
   * the table data.
   */
  const patientsWithTableData = useMemo(
    () =>
      patients.map((p) => ({
        ...p,
        tableData: { checked: selectedPatientIds.includes(p.id) },
      })),
    [patients, selectedPatientIds],
  );

  const deselectAll = () => {
    setSelectedPatientIds([]);
  };

  const reloadAllPractices = async () => {
    const response = await api.getPractices();
    setAllPractices(response.data.practices);
  };

  useEffect(() => {
    reloadAllPractices();
  }, []);

  useEffect(() => {
    /**
     *  When assigning **practices** we want to pre-populate the assigned practices
     *  with the patient's current practices. This allows us to add or remove based on the
     *  user's selection.
     */
    if (assignmentType === AssignmentType.Practice) {
      const selectedPatients = patients.filter((p) => selectedPatientIds.includes(p.id));

      const val = _.fromPairs(selectedPatients.map((p) => [p.id, p.practices]));
      setAssignedPractices(val);
    }
  }, [assignmentType, selectedPatientIds, patients]);

  const { startJourney: startAdmitJourney } = usePatientAdmitJourney({
    onClose: () => reloadPatients(),
    showPatientRecordLink: true,
  });

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleAddPatientClick = () => {
    startAdmitJourney();
  };

  const deletePatient = async (patient: ManagePatientItemFragment) => {
    const inner = async () => {
      const response = await api.deletePatient(patient.id);
      if (!response.errors) {
        setFlashMessage('Patient removed');
        handleCloseDeleteDialog();
        reloadPatients();
        setActivePatient(undefined);
      }
    };
    inner();
  };

  const handleAssignmentTypeChange = (type: AssignmentType | null) => {
    setAssignmentType(type);
  };

  const saveAssignedPractices = async () => {
    const promisePool = [];
    for (const [patientId, newPractices] of Object.entries(assignedPractices)) {
      const patient = _.find(patients, ['id', patientId]);

      if (!patient) {
        continue;
      }

      const currentPractices = patient.practices;

      const subtractions = _.differenceBy(currentPractices, newPractices, 'id');
      const additions = _.differenceBy(newPractices, currentPractices, 'id');

      for (const { id: practiceId } of additions) {
        promisePool.push(api.assignPatientToPractice({ patientId, practiceId }));
      }
      for (const { id: practiceId } of subtractions) {
        promisePool.push(api.unassignPatientFromPractice({ patientId, practiceId }));
      }
    }
    // TODO: Error handling
    await Promise.all(promisePool);
    if (promisePool.length > 0) {
      reloadPatients();
    }
    setAssignmentType(null);
    deselectAll();
  };

  const handleUnassignedPracticeClick = (rowData: WithTableData<ManagePatientItemFragment>) => {
    setSelectedPatientIds((prev) => _.xor(prev, [rowData.id]));
    setAssignmentType(AssignmentType.Practice);
  };

  const resetPasswordClick = (newActivePatient: WithTableData<ManagePatientItemFragment>) => {
    setActivePatient(newActivePatient);
    setSetPasswordModalOpen(true);
  };

  const columns: Column<ManagePatientItemFragment>[] = [
    {
      field: 'id',
      hidden: true,
      searchable: true,
      customFilterAndSearch(filter, rowData) {
        // Exact (case insensitive) match on the patient ID
        return rowData.id.toLowerCase() === filter.toLowerCase();
      },
    },
    {
      field: 'firstName',
      title: MaterialTableWithIconsHeaderWithId('patient_list_header_first_name', 'First Name'),
      defaultSort: 'asc',
    },
    {
      field: 'lastName',
      title: MaterialTableWithIconsHeaderWithId('patient_list_header_last_name', 'Last Name'),
      defaultSort: 'asc',
    },
    { field: 'lastName', hidden: true },
    {
      field: 'birthDate',
      title: MaterialTableWithIconsHeaderWithId('patient_list_header_birth_date', 'Birth Date'),
      type: 'date',
      render: ({ birthDate }) =>
        t('DATE_SHORT', {
          val: new Date(birthDate),
          // We use formatParams to force a timezone of UTC with the underlying Intl.DateTimeFormat
          // function. This ensures that birthdates remain timezone agnostic, which is what most
          // people expect, culturally.
          // see: https://www.i18next.com/translation-function/formatting#datetime
          formatParams: { val: { timeZone: 'UTC' } },
        }),
    },
    {
      field: 'gender',
      title: MaterialTableWithIconsHeaderWithId('patient_list_header_gender', 'Gender'),
      sorting: false,
      cellStyle: { textTransform: 'capitalize' },
    },
    {
      title: MaterialTableWithIconsHeaderWithId('patient_list_gp_practice', t('GP Practice')),
      sorting: false,
      // eslint-disable-next-line react/display-name
      render: (row) =>
        row.practices.length > 0 ? (
          row.practices.map((practice) => (
            <Chip
              key={practice.id}
              icon={<LocalHospitalIcon />}
              label={practice.name}
              className={classes.chip}
              onClick={(event) => {
                event.stopPropagation(); // this is needed for the patient row to be clickable in Manage Patients
              }}
            />
          ))
        ) : (
          <ChipWithTooltip
            variant="outlined"
            tooltip={`No ${t('GP Practice')} assigned`}
            label="Unassigned"
            onClick={(event: React.MouseEvent) => {
              handleUnassignedPracticeClick(row as WithTableData<ManagePatientItemFragment>);
              event.stopPropagation(); // this is needed for the patient row to be clickable in Manage Patients
            }}
          />
        ),
    },
    {
      title: MaterialTableWithIconsHeaderWithId('patient_list_wards', t('Wards')),
      sorting: false,
      hidden: !wardsEnabled,
      // eslint-disable-next-line react/display-name
      render: (row) =>
        row.wards.length > 0 ? (
          row.wards.map((ward) => (
            <Chip
              key={ward.id}
              icon={<LocalHospitalIcon />}
              label={ward.name}
              className={classes.chip}
              onClick={(event) => {
                event.stopPropagation(); // this is needed for the patient row to be clickable in Manage Patients
              }}
            />
          ))
        ) : (
          <ChipWithTooltip
            variant="outlined"
            tooltip={`No ${t('Ward')} assigned`}
            label="Unassigned"
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation(); // this is needed for the patient row to be clickable in Manage Patients
            }}
          />
        ),
    },
    {
      field: 'createdAt',
      title: MaterialTableWithIconsHeaderWithId(
        'patient_list_registered_with_feebris',
        'Registered with Feebris',
      ),
      type: 'date',
      render: ({ createdAt }) => t('DATE_SHORT', { val: new Date(createdAt) }),
    },
    {
      field: 'nhsNumber',
      title: MaterialTableWithIconsHeaderWithId('patient_list_nhs_number', 'NHS Number'),
      hidden: !auth.me('actingOrganization.features.nhsNumberRetrieval', false),
      render: (rowData) => <NhsNumber patientId={rowData.id} nhsNumber={rowData.nhsNumber} />,
    },
  ];

  return (
    <>
      <PatientFormModal
        patient={activePatient}
        open={patientFormModalOpen}
        onClose={() => {
          setPatientFormModalOpen(false);
          setActivePatient(undefined);
        }}
        onComplete={reloadPatients}
        setFlashMessage={setFlashMessage}
      />
      <AssignPracticeAlert
        open={assignPracticeAlertOpen}
        onClose={() => setAssignPracticeAlertOpen(false)}
        onProceed={() => {
          saveAssignedPractices();
          setAssignPracticeAlertOpen(false);
        }}
      />
      <AddPracticeDialog
        open={addPracticeOpen}
        onClose={() => setAddPracticeOpen(false)}
        onCreatePractice={(newCreatedPractice: GraphQLOrganization) => {
          reloadAllPractices();
          setAddPracticeOpen(false);
          // FIXME: Refactor is getting desperate here. Need to DRY this out into a data manager.
          const newAssignedPractices = _.fromPairs(
            Object.entries(assignedPractices).map(([patientId, practices]) => {
              const newPractices = _.uniqBy(practices.concat([newCreatedPractice]), (p) => p.id);
              return [patientId, newPractices];
            }),
          );
          setAssignedPractices(newAssignedPractices);
        }}
      />
      <DeletePatientModal
        patient={activePatient}
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onSubmit={() => activePatient && deletePatient(activePatient)}
      />
      {activePatient?.selfCare?.email && (
        <SetPasswordModal
          open={setPasswordModalOpen}
          onClose={() => {
            setSetPasswordModalOpen(false);
          }}
          onExited={() => setActivePatient(undefined)}
          user={activePatient?.selfCare}
          setFlashMessage={setFlashMessage}
        />
      )}
      <Grid container>
        <Grid item xs={12} className="e2e__patientstable">
          <MaterialTableWithIcons
            isLoading={isLoading}
            columns={columns}
            data={patientsWithTableData}
            title={null}
            options={{
              selection: true,
              search: true,
              searchFieldStyle: {
                marginRight: 10,
              },
              pageSize: 10,
              showEmptyDataSourceMessage: !isLoading,
            }}
            localization={{ toolbar: { searchPlaceholder: 'Search by name' } }}
            onSelectionChange={(rows) => {
              setSelectedPatientIds(rows.map((p) => p.id));
            }}
            actions={
              [
                {
                  icon: 'HACK-edit-resident',
                  tooltip: 'Edit patient',
                  position: 'row',
                },
                auth.me('permissions.delete_patients')
                  ? {
                      icon: 'HACK-remove-resident',
                      tooltip: 'Remove patient',
                      position: 'row',
                    }
                  : undefined,
                auth.me('permissions.create_patients')
                  ? {
                      icon: 'HACK-reset-password',
                      tooltip: 'Reset password',
                      position: 'row',
                    }
                  : undefined,
                {
                  icon: 'HACK-assign-gp-practice',
                },
                auth.me('permissions.create_patients')
                  ? {
                      icon: 'HACK-add-resident',
                      isFreeAction: true,
                    }
                  : undefined,
                !auth.me('actingOrganization.features.selfCare', false) &&
                auth.me('permissions.create_patients') &&
                auth.me('permissions.create_users')
                  ? {
                      icon: 'HACK-more-actions',
                      isFreeAction: true,
                    }
                  : undefined,
              ] as Action<ManagePatientItemFragment>[]
            }
            onRowClick={(_, rowData) => rowData && onRowClick(rowData)}
            components={{
              OverlayLoading: () => {
                return <Loading showLoading />;
              },
              // eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
              Action: (props: any) => {
                const isSelfCareInActingOrg =
                  props.data.selfCare &&
                  _.some(
                    props.data.selfCare.organizations,
                    (org) => org.id === auth.me('actingOrganization.id'),
                  );

                if (props.action.icon === 'HACK-add-resident') {
                  return (
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<PersonAddIcon />}
                      onClick={handleAddPatientClick}
                      className={clsx(classes.addPatientButton, 'e2e__addresidentbutton')}>
                      Add Patient
                    </Button>
                  );
                } else if (props.action.icon === 'HACK-more-actions') {
                  return <MoreActionsMenu setOpen={openPatientBulkUploadModal} />;
                } else if (props.action.icon === 'HACK-edit-resident') {
                  return (
                    <Tooltip title="Edit patient">
                      <IconButtonNoPropagation
                        aria-label="edit"
                        onClick={() => {
                          setActivePatient(props.data);
                          setPatientFormModalOpen(true);
                        }}>
                        <EditIcon className="e2e__editresident" />
                      </IconButtonNoPropagation>
                    </Tooltip>
                  );
                } else if (props.action.icon === 'HACK-remove-resident') {
                  return (
                    <Tooltip title="Remove patient">
                      <IconButtonNoPropagation
                        aria-label="delete"
                        onClick={() => {
                          setActivePatient(props.data);
                          handleOpenDeleteDialog();
                        }}>
                        <CancelIcon className="e2e__removeresident" />
                      </IconButtonNoPropagation>
                    </Tooltip>
                  );
                } else if (props.action.icon === 'HACK-reset-password') {
                  return isSelfCareInActingOrg ? (
                    <Tooltip
                      title={
                        props.data.selfCare?.canResetPassword
                          ? 'Reset password'
                          : 'Cannot reset password because the email address used by this patient is associated with a Feebris login in another organisation'
                      }>
                      <span>
                        <IconButtonNoPropagation
                          aria-label="reset password"
                          onClick={() => resetPasswordClick(props.data)}
                          disabled={!props.data.selfCare?.canResetPassword}>
                          <VpnKeyIcon className="e2e__resetpasswordbutton" />
                        </IconButtonNoPropagation>
                      </span>
                    </Tooltip>
                  ) : null;
                } else if (props.action.icon === 'HACK-assign-gp-practice') {
                  return (
                    <PatientAssignmentToolbar
                      assignedPractices={assignedPractices}
                      setAssignedPractices={setAssignedPractices}
                      setAddPracticeOpen={setAddPracticeOpen}
                      setAssignPracticeAlertOpen={setAssignPracticeAlertOpen}
                      allPractices={allPractices}
                      assignmentType={assignmentType}
                      onAssignmentTypeChange={handleAssignmentTypeChange}
                      onCancel={() => {
                        deselectAll();
                        setAssignmentType(null);
                      }}
                    />
                  );
                }
                return <MTableAction {...props} />;
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
  actions: {
    // eslint-disable-next-line quote-props
    display: 'flex',
    // eslint-disable-next-line quote-props
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  assignPracticeOption: {
    display: 'flex',
    flexDirection: 'column',
  },
  addPatientButton: {
    height: '100%',
    alignSelf: 'center',
  },
}));
