import React, { useMemo, useState } from 'react';

import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import { Helmet } from 'react-helmet-async';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { toast } from 'sonner';

import ChipWithTooltip from '@/components/ChipWithTooltip';
import MaterialTableWithIcons from '@/components/MaterialTableWithIcons';

import api from '@/controllers/Api';
import UserFormModal from './components/UserFormModal';
import ResetPasswordModal from '@/components/ResetPasswordModal';
import { DateTimeFormatter } from '@/helpers/LocaleFormatting';
import auth from '@/controllers/Auth';
import {
  Role,
  useUserSettingsUsersQuery,
  UserSettingsFragment,
  useUnenrollFromTwoFactorAuthMutation,
  useDeleteUserMutation,
} from '@/generated/graphql';
import { Column, Action, MTableAction } from '@material-table/core';
import { ErrorDisplay } from '@/components/ErrorDisplay';
import { Alert } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { RestrictedRouteTester } from '@/components/ProtectedRoute';
import { useAddUserJourney } from '@/components/Journeys/AddUserJourney';

const DeleteUserDialog = (props: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
  content: React.ReactNode;
  cancelButtonText?: string;
  confirmButtonText?: string;
}) => (
  <Dialog
    className="e2e_delete_user_dialog"
    open={props.open}
    onClose={props.onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description">
    <DialogTitle id="alert-dialog-title">{props.title || 'Are you sure?'}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">{props.content}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.onClose} autoFocus>
        {props.cancelButtonText || 'Cancel'}
      </Button>
      <Button
        className="e2e_modal_confirm_btn"
        onClick={props.onConfirm}
        color="primary"
        variant="contained">
        {props.confirmButtonText || 'Confirm'}
      </Button>
    </DialogActions>
  </Dialog>
);

export const QUERY_USER_ORGANIZATIONS = gql`
  fragment UserSettings on UserOrganization {
    user {
      id
      email
      firstName
      lastName
      wards {
        id
        name
      }
      enrolledInTotpMfa
      isQuicksilvaIdentitySetup
      canResetPassword
    }
    roles
    lastLoggedInAt
  }

  query UserSettingsUsers {
    userOrganizations {
      ...UserSettings
    }
  }
`;

export const MUTATION_UNENROLL_MFA = gql`
  mutation unenrollFromTwoFactorAuth($userId: ID!) {
    unenrollFromTwoFactorAuth(userId: $userId)
  }
`;

export const MUTATION_DELETE_USER = gql`
  mutation deleteUser($userId: ID!) {
    deleteUser(userId: $userId)
  }
`;

const flattenUserOrganization = (userOrganizationLink: UserSettingsFragment) => ({
  ...userOrganizationLink.user,
  roles: userOrganizationLink.roles,
  lastLoggedInAt: userOrganizationLink.lastLoggedInAt,
});

type FlattenedUser = ReturnType<typeof flattenUserOrganization>;

export const canAccessManageUsersPage: RestrictedRouteTester = ({ permissions }) =>
  permissions['edit_users'];

const Users = () => {
  const classes = useStyles();
  const [userFormModalOpen, setUserFormModalOpen] = useState(false);
  const [deleteUserDialogOpen, setDeleteUserDialogOpen] = useState(false);
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const [user, setUser] = useState<FlattenedUser | null>(null);

  const { t } = useTranslation();

  const { startJourney: startAddUserJourney } = useAddUserJourney({
    onClose: () => {
      refetch();
    },
  });

  const { data, refetch, error } = useUserSettingsUsersQuery({
    onError: () => toast.error('An error occurred while loading users'),
  });

  const [deleteUserMutation] = useDeleteUserMutation({
    onError: ({ graphQLErrors }) => {
      toast.error(
        // @ts-expect-error: Unsure how to type this
        graphQLErrors?.[0]?.extensions?.invalidArgs?.userId ??
          'An error occurred while deleting the user',
      );
    },
  });

  const flattened = useMemo(
    () => (data ? data.userOrganizations.map(flattenUserOrganization) : []),
    [data],
  );

  const [unenrollFromTwoFactor, { loading: unenrollingTwoFactor }] =
    useUnenrollFromTwoFactorAuthMutation({
      onError: () => toast.error('An error occurred when unenrolling user from MFA'),
      onCompleted: () => refetch(),
    });

  const wardsEnabled = auth.me('actingOrganization.features.wards', false);

  const editClick = (newActiveUser: FlattenedUser) => {
    setUser(newActiveUser);
    setUserFormModalOpen(true);
  };

  const deleteClick = (newActiveUser: FlattenedUser) => {
    setUser(newActiveUser);
    setDeleteUserDialogOpen(true);
  };

  const addUserClick = () => {
    startAddUserJourney();
  };

  const resetPasswordClick = (newActiveUser: FlattenedUser) => {
    setUser(newActiveUser);
    setResetPasswordModalOpen(true);
  };

  const confirm = useConfirm();

  const confirmDisableTwoFactor = async (user: FlattenedUser) => {
    try {
      await confirm({
        description: (
          <>
            Are you sure you want to disable two-factor authentication for{' '}
            <Typography component="i">{user.email}</Typography>?
          </>
        ),
        confirmationButtonProps: {
          variant: 'contained',
          color: 'error',
        },
        confirmationText: 'Disable two-factor authentication',
      });

      await unenrollFromTwoFactor({
        variables: { userId: user.id },
      });
    } catch {
      // catch the weird error when the 'cancel' button is clicked
    }
  };

  const columns = useMemo(
    (): Column<FlattenedUser>[] => [
      {
        field: 'firstName',
        title: 'First Name',
        render: (rowData) => rowData.firstName || <span>—</span>,
      },
      {
        field: 'lastName',
        title: 'Last Name',
        render: (rowData) => rowData.lastName || <span>—</span>,
      },
      { field: 'email', title: 'Email', defaultSort: 'asc' },
      {
        title: 'Assigned Wards',
        sorting: false,
        hidden: !wardsEnabled,
        render: (row) =>
          row.wards.length > 0 ? (
            row.wards.map((ward) => (
              <Chip
                key={ward.id}
                icon={<LocalHospitalIcon />}
                label={ward.name}
                className={classes.chip}
                component={Link}
                to={`/wards/${ward.id}/staff`}
              />
            ))
          ) : (
            <ChipWithTooltip
              variant="outlined"
              tooltip={`No ${t('Ward')} assigned`}
              label="Unassigned"
            />
          ),
      },
      { field: 'roles', title: 'Role' },
      {
        field: 'lastLoggedInAt',
        title: 'Last logged in',
        render: (rowData) =>
          rowData.lastLoggedInAt ? (
            DateTimeFormatter.toLocaleDateTime(rowData.lastLoggedInAt)
          ) : (
            <Typography component="i" variant="body2">
              Has never logged in
            </Typography>
          ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [wardsEnabled, t, flattened],
  );

  const allRoles: Role[] = auth.me('actingOrganization.roles', []);
  const systemRoles = allRoles.filter((role) => role.isSystemRole).map((role) => role.name);
  const containsSystemRoles = (roles: Array<string | undefined | null>) =>
    _.intersection(roles, systemRoles).length > 0;

  return (
    <>
      <Helmet>
        <title>Manage Users</title>
      </Helmet>
      <Box className="e2e__userstable">
        <MaterialTableWithIcons
          title="Users"
          columns={columns}
          data={flattened}
          options={{ search: true, pageSize: 20, emptyRowsWhenPaging: false }}
          localization={{
            body: {
              emptyDataSourceMessage: error ? (
                <ErrorDisplay
                  message="Failed to fetch users. Press retry to try again."
                  retry={refetch}
                />
              ) : (
                t('Organization has no users')
              ),
            },
          }}
          actions={[
            // FIXME: Should hide this action when no create_users permissions
            {
              icon: 'HACK-add-user',
              isFreeAction: true,
            } as Action<FlattenedUser>,
            // FIXME: Should hide this action when no edit_users permissions
            (rowData) => ({
              icon: () => <EditIcon />,
              disabled: containsSystemRoles(rowData.roles),
              tooltip: containsSystemRoles(rowData.roles)
                ? 'Self-care user cannot be edited directly. Edit the patient instead.'
                : 'Edit User',
              onClick: (event, rowData) => editClick(rowData as FlattenedUser),
            }),
            // FIXME: Should hide this action when no delete_users permissions
            (rowData) => ({
              icon: () => <DeleteIcon />,
              disabled: containsSystemRoles(rowData.roles) || auth.me('id') === rowData.id,
              tooltip: containsSystemRoles(rowData.roles)
                ? 'Self-care user cannot be removed directly. Remove the patient instead.'
                : auth.me('id') === rowData.id
                ? 'Cannot remove your own user'
                : 'Remove User',
              onClick: (event, rowData) => deleteClick(rowData as FlattenedUser),
            }),
            // FIXME: Should hide this action when no edit_users permissions
            (rowData) => ({
              icon: () => <VpnKeyIcon className="e2e__resetuserpasswordbutton" />,
              disabled: !rowData.canResetPassword,
              tooltip: rowData.canResetPassword
                ? 'Reset user password'
                : 'Cannot reset password because this user is in multiple organisations',
              onClick: (event, rowData) => resetPasswordClick(rowData as FlattenedUser),
            }),
            // FIXME: Should hide this action when no edit_users permissions
            (rowData) => ({
              icon: () => <LockOpenIcon />,
              tooltip: 'Disable Two-Factor Authentication',
              disabled: unenrollingTwoFactor,
              onClick: async (event, rowData) => {
                await confirmDisableTwoFactor(rowData as FlattenedUser);
              },
              hidden: rowData.enrolledInTotpMfa === false,
            }),
          ]}
          components={{
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            Action: (props: any) => {
              if (props.action.icon === 'HACK-add-user') {
                return (
                  <Button
                    sx={{ marginLeft: 2 }}
                    startIcon={<PersonAddIcon />}
                    onClick={addUserClick}
                    variant="contained"
                    color="primary">
                    Add User
                  </Button>
                );
              }
              return <MTableAction {...props} />;
            },
          }}
        />
      </Box>
      {user && (
        <UserFormModal
          open={userFormModalOpen}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          user={user}
          onClose={() => {
            setUserFormModalOpen(false);
            setUser(null);
            return refetch();
          }}
        />
      )}

      {user && (
        <DeleteUserDialog
          open={Boolean(deleteUserDialogOpen)}
          onClose={() => setDeleteUserDialogOpen(false)}
          onConfirm={async () => {
            // The state is a bit loose in this file, but we know that if the dialog is open, the user is defined
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const result = await deleteUserMutation({ variables: { userId: user!.id } });
            if (!result.errors) {
              toast.success(`${user.email} successfully removed`);
            }
            setDeleteUserDialogOpen(false);
            return refetch();
          }}
          content={
            user && (
              <>
                <Typography paragraph>
                  Are you sure you want to remove{' '}
                  <Typography component="i">{user.email}</Typography>?
                </Typography>
                <Typography paragraph>
                  This will stop <Typography component="i">{user.email}</Typography> from being able
                  to log in to the {auth.me('actingOrganization.name')} organisation in Feebris
                </Typography>

                {(user.wards?.length > 0 || user.isQuicksilvaIdentitySetup) && (
                  <Alert severity="warning">
                    {user.wards?.length > 0 && (
                      <Typography gutterBottom>
                        This user is assigned to wards. Deleting them will unassign them from all
                        wards that they are assigned to.
                      </Typography>
                    )}

                    {user.isQuicksilvaIdentitySetup && (
                      <Typography gutterBottom>
                        This user has a Quicksilva identity set up and may be assigned as an
                        auto-approver for integrations. If they are, deleting them will leave the
                        intergations without an auto-approver.
                      </Typography>
                    )}
                  </Alert>
                )}
              </>
            )
          }
        />
      )}
      {user && (
        <ResetPasswordModal
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          fetchPasswordResetUrl={() => api.passwordResetUrl({ userId: user!.id })}
          open={resetPasswordModalOpen}
          onClose={() => setResetPasswordModalOpen(false)}
          user={user}
          onError={(message: string) => toast.error(message)}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
}));

export default Users;
