import React from 'react';

import { gql } from '@apollo/client';
import { Action, Column } from '@material-table/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import RemoveIcon from '@mui/icons-material/RemoveCircle';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button } from '@mui/material';

import MaterialTableWithIcons from '@/components/MaterialTableWithIcons';
import { useWardPatientsQuery, WardPatientItemFragment } from '@/generated/graphql';
import NhsNumber from '@/components/NhsNumber';
import auth from '@/controllers/Auth';
import { usePatientDischargeModal } from '@/components/patientDischarge';
import { ErrorDisplay } from '@/components/ErrorDisplay';

export const QUERY_WARD_PATIENTS = gql`
  fragment WardPatientItem on WardPatient {
    patient {
      id
      firstName
      lastName
      birthDate
      gender
      nhsNumber
    }
    carePathway {
      id
      name
    }
    admittedAt
  }

  query WardPatients($wardId: ID!) {
    ward(id: $wardId) {
      id
      wardPatients {
        ...WardPatientItem
      }
    }
  }
`;

interface WardPatientsProps {
  wardId: string;
  wardName: string;
  onRemoved: () => void;
}

export function WardPatients({ wardId, wardName, onRemoved }: WardPatientsProps) {
  const { data, loading, refetch, error } = useWardPatientsQuery({
    variables: { wardId },
  });

  const { t } = useTranslation();
  const { showPatientDischargeModal } = usePatientDischargeModal({
    onPatientDischarged: () => {
      refetch();
      onRemoved();
    },
  });

  type ActionWithPermission = Action<WardPatientItemFragment> & { permission?: string };

  const actions: ActionWithPermission[] = [
    {
      permission: 'discharge_ward_patients',
      icon: () => <RemoveIcon />,
      tooltip: 'Discharge patient from ward',
      position: 'row',
      onClick: (_, rowData) => {
        showPatientDischargeModal({
          patient: (rowData as WardPatientItemFragment).patient,
          currentAdmission: {
            ward: { id: wardId, name: wardName },
            carePathway: (rowData as WardPatientItemFragment).carePathway,
            admittedAt: (rowData as WardPatientItemFragment).admittedAt,
          },
        });
      },
    },
  ];

  const columns: Column<WardPatientItemFragment>[] = [
    { field: 'patient.id', title: 'ID', hidden: true },
    { field: 'patient.firstName', title: 'First Name' },
    { field: 'patient.lastName', title: 'Last Name' },
    {
      field: 'patient.nhsNumber',
      title: 'NHS Number',
      render: ({ patient: { nhsNumber, id } }) => (
        <NhsNumber nhsNumber={nhsNumber} patientId={id} />
      ),
    },
    {
      field: 'patient.birthDate',
      title: 'Date of Birth',
      render: ({ patient: { birthDate } }) =>
        t('DATE_SHORT', {
          val: new Date(birthDate),
          formatParams: { val: { timeZone: 'UTC' } },
        }),
      type: 'datetime',
    },
    { field: 'carePathway.name', title: 'Care Pathway' },
    { field: 'admittedAt', title: 'Admitted', type: 'datetime' },
    {
      field: 'patient.id',
      width: 'auto',
      align: 'right',
      render: (data) => (
        <Button
          component={Link}
          style={{ lineHeight: 1 }}
          to={`/patient/${data.patient.id}`}
          size="small"
          color="primary"
          endIcon={<ChevronRightIcon />}>
          View Patient
        </Button>
      ),
    },
  ];

  return (
    <MaterialTableWithIcons
      title="Ward Patients"
      actions={actions.filter(
        (action) => !action.permission || auth.me(`permissions.${action.permission}`),
      )}
      options={{
        tableLayout: 'auto',
        tableWidth: 'full',
      }}
      localization={{
        body: {
          emptyDataSourceMessage: error ? (
            <ErrorDisplay message="Failed to fetch patients for this ward" retry={refetch} />
          ) : (
            t('Ward has no patients')
          ),
        },
      }}
      isLoading={loading}
      columns={columns}
      data={data?.ward.wardPatients ?? []}
    />
  );
}
