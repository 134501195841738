import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconBlackFactory from './images/CE/Icon_Black_Factory.svg';
import IconInstructions from './images/CE/Icon_Instructions.svg';
import '@/styles/elements/footer.scss';
import Dialog from '@mui/material/Dialog';

export default function Footer() {
  const [open, setOpen] = React.useState(false);

  return (
    <footer className="no-print">
      <Box pt={2} pb={3} textAlign="center" fontSize={15}>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <VersionInformation />
        </Dialog>
        <div className="modal-open" onClick={(e) => setOpen(true)}>
          Feebris OS Product Version Information
        </div>
      </Box>
    </footer>
  );
}

function VersionInformation(props) {
  const [expanded, setExpanded] = React.useState(false);
  const togglePanel = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const useStyles = makeStyles((theme) => ({
    container: {
      padding: theme.spacing(2, 0, 2, 0),
    },
    title: {
      fontWeight: 'bold',
      marginTop: theme.spacing(1),
      letterSpacing: 1,
    },
    version: {
      fontWeight: 'bold',
      letterSpacing: 1,
      margin: theme.spacing(1, 0, 0, 0),
    },
    iconRows: {
      margin: theme.spacing(1, 0, 1, 0),
    },
    expansionPanels: {
      margin: theme.spacing(1, 0, 1, 0),
    },
    icon: {
      width: theme.spacing(7),
      height: theme.spacing(5),
      margin: theme.spacing(0, 2, 0, 0),
    },
    limitationBlockTitle: {
      fontWeight: 'bold',
      fontSize: 14,
      letterSpacing: 1,
    },
  }));

  const classes = useStyles();

  return (
    <Grid container justifyContent="center">
      <Grid item xs={11} container className={classes.container} direction="row">
        <Grid item xs={12} container direction="row">
          <Grid item container direction="row">
            <Typography variant="h5" className={classes.title}>
              Feebris OS
            </Typography>
          </Grid>
          <Grid item xs={12} container>
            <Typography variant="h6" className={classes.version}>
              Version: {process.env.REACT_APP_VERSION}{' '}
              {`(${process.env.REACT_APP_GIT_BRANCH}-${process.env.REACT_APP_GIT_HASH})`}
            </Typography>
          </Grid>
          <Grid item xs={12} container className={classes.iconRows}>
            <img className={classes.icon} src={IconBlackFactory} alt="black factory symbol" />
            <Typography>
              Feebris Ltd
              <br />
              Ground Floor, Cromwell House
              <br />
              15 Andover Road
              <br />
              Winchester
              <br />
              SO23 7BT
              <br />
              United Kingdom
            </Typography>
          </Grid>
          <Grid item xs={12} container className={classes.iconRows}>
            <img className={classes.icon} src={IconInstructions} alt="instructions symbol" />
            <Typography>
              <a
                href="https://feebriscom.sharepoint.com/:p:/s/DefinitiveMediaLibrary/EYfjBnLEXNpEstLMRQLoQKIBrPd2_BtqAiKLaeU0GUaptw?e=5u3GIa"
                target="_blank"
                rel="noopener noreferrer">
                Feebris Web App User Guide
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12} container className={classes.expansionPanels}>
            <Accordion expanded={expanded === 'p1'} onChange={togglePanel('p1')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.limitationBlockTitle}>Intended Purpose</Typography>
              </AccordionSummary>
              <AccordionDetails>
                Feebris OS web application is software intended (in situations where intervention is
                normally not expected to be time critical in order to avoid death, long-term
                disability or other serious deterioration of health) to enable remote clinical
                review of captured data and insights to support patient triage, diagnosis and
                treatment by a qualified medical practitioner.
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'p2'} onChange={togglePanel('p2')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.limitationBlockTitle}>Contraindications</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <span>
                  Feebris OS is not intended to be used:
                  <ul>
                    <li>
                      in circumstances where an accurate and/or timely diagnosis or treatment action
                      is vital to avoid death, long-term disability or other serious deterioration
                      of health;
                    </li>
                    <li>on patients under the age 16;</li>
                    <li>on pregnant women;</li>
                    <li>
                      with sensors that are not certified medical devices against the MDD/MDR or
                      FDA;
                    </li>
                    <li>for confirmation / verification of death;</li>
                    <li>by unqualified lay persons.</li>
                  </ul>
                </span>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'p3'} onChange={togglePanel('p3')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.limitationBlockTitle}>Warnings</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <span>
                  Read the following warnings carefully before using the software:
                  <ul>
                    <li>
                      The result may be inaccurate if inputs are incorrect. Please ensure that
                      manual inputs are carefully entered, and that any connected medical sensors
                      are used according to their instructions for use.
                    </li>
                    <li>
                      Completing a check-up against the wrong patient could lead to incorrect
                      results and incorrect diagnosis or treatment decisions down the road.
                    </li>
                    <li>
                      The software is not intended to diagnose or treat any health problem or
                      disease. The results are for reference only.
                    </li>
                    <li>
                      The patient may be at risk of health problems or diseases that are not covered
                      by Feebris OS.
                    </li>
                    <li>Please consult a doctor for any diagnosis or treatment decisions.</li>
                    <li>Update the software whenever an update is available.</li>
                    <li>Do not leave the software logged in and unattended.</li>
                    <li>
                      If photos taken are unclear or ambiguous (for example, if the body part could
                      be misinterpreted) this could lead to incorrect results and incorrect
                      diagnosis or treatment decisions down the road.
                    </li>
                  </ul>
                </span>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
