import {
  EhrIdentityType,
  EhrIntegrationTriggerType,
  EhrIntegrationType,
  FormattableIntegrationTriggerConfigFragment,
} from '@/generated/graphql';
import { formatRRule } from '@/helpers/formatRRule';

const triggerTypeTextMap: Record<
  EhrIntegrationTriggerType,
  (config: Maybe<FormattableIntegrationTriggerConfigFragment>) => string
> = {
  [EhrIntegrationTriggerType.PeriodicSummary]: (
    config: Maybe<FormattableIntegrationTriggerConfigFragment>,
  ) =>
    `Periodic Summary (${
      config && config.__typename === 'EhrIntegrationPeriodicTriggerConfig'
        ? formatRRule(config.rrule)
        : '?'
    })`,
  [EhrIntegrationTriggerType.DataPoint]: (_: Maybe<FormattableIntegrationTriggerConfigFragment>) =>
    'Data Point',
};

export const formatIntegrationTriggerType = (
  type: EhrIntegrationTriggerType,
  config: Maybe<FormattableIntegrationTriggerConfigFragment>,
) => triggerTypeTextMap[type]?.(config) ?? 'Unknown';

const integrationTypeTextMap: Record<EhrIntegrationType, string> = {
  QuicksilvaMessage: 'Quicksilva Toolbar',
};

export const formatIntegrationType = (type: EhrIntegrationType) =>
  integrationTypeTextMap[type] ?? 'Unknown';

const identityTypeTextMap: Record<EhrIdentityType, string> = {
  Quicksilva: 'Quicksilva',
};

export const formatIdentityType = (type: EhrIdentityType) => identityTypeTextMap[type] ?? 'Unknown';
